import { useLocation } from 'react-router-dom';
import styled from '@emotion/styled';

import EnterpriseRegisterForm from 'src/forms/enterprise/EnterpriseRegisterForm';
import {
  errorColor,
  maxPageWidth,
  responsiveMaxPageWidth,
} from 'src/styles/constants';

const StyledPageContainer = styled.div`
  width: 100%;
  max-width: ${maxPageWidth}px;
  margin: 3rem auto;

  ${responsiveMaxPageWidth}
`;

const Error = ({ attribute }: { attribute: string }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <h3 style={{ color: errorColor }}>{attribute} not found</h3>
  </div>
);

const EnterpriseRegisterPage = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const email = queryParams.get('email');
  const organizationId = Number(queryParams.get('abc'));

  if (!email) {
    return (
      <StyledPageContainer>
        <Error attribute='Email' />
      </StyledPageContainer>
    );
  } else if (!organizationId) {
    return (
      <StyledPageContainer>
        <Error attribute='Organization' />
      </StyledPageContainer>
    );
  }

  return (
    <StyledPageContainer>
      <EnterpriseRegisterForm organizationId={organizationId} email={email} />
    </StyledPageContainer>
  );
};

export default EnterpriseRegisterPage;
