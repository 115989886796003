import { useTranslation } from 'react-i18next';
import React, { MouseEventHandler } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Typography, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import styled from '@emotion/styled';

import StripeCard from './StripeCard';
import AddressForm from '../../forms/AddressForm';
import { RootState } from '../../store';
import { BillingAddress } from '../../store/models/UserModels';
import {
  FlexColumn,
  FlexRow,
  primaryColor2,
  textColorSecondary,
} from '../../styles/constants';
import CheckoutSummary from './CheckoutSummary';
import { LoadingSpinner } from '../../components';
import { PaygTranslationQuote } from 'src/store/models/TranslationModels';

interface PaymentInfoProps {
  paygTranslationQuote: PaygTranslationQuote;
  completeStep: () => void;
  goBackToQuoteStep: () => void;
}

const StyledContainer = styled.div`
  width: 100%;
  align-self: flex-start;
  margin-top: 3rem;
`;

const EditButton = styled(IconButton)`
  margin-left: 8px;
`;

interface DisplayBillingAddressProps {
  billingAddress: BillingAddress;
  onClick: MouseEventHandler<HTMLButtonElement>;
  t: any;
}

const DisplayBillingAddress = ({
  billingAddress,
  onClick,
  t,
}: DisplayBillingAddressProps) => {
  return (
    <div style={{ padding: '1rem' }}>
      <FlexColumn addStyles='align-items: flex-start;'>
        <FlexRow addStyles='width: 100%; justify-content: flex-start;'>
          <Typography variant='h6'>{t('Billing Address')}</Typography>
          <EditButton
            sx={{ color: primaryColor2, marginLeft: '2rem !important' }}
            aria-label='edit billing address'
            size='small'
            onClick={onClick}
          >
            <EditIcon />
          </EditButton>
        </FlexRow>
        <Typography variant='body1' sx={{ color: textColorSecondary }}>
          {billingAddress.street}
        </Typography>
        <Typography variant='body1' sx={{ color: textColorSecondary }}>
          {billingAddress.city}, {billingAddress.region.label},{' '}
          {billingAddress.postalCode}
        </Typography>
        <Typography variant='body1' sx={{ color: textColorSecondary }}>
          {billingAddress.country.label}
        </Typography>
      </FlexColumn>
    </div>
  );
};

const PaymentInfo = ({ completeStep, goBackToQuoteStep }: PaymentInfoProps) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.user);
  const { paygTranslationPayment, createPaygTranslationPayment } = useSelector(
    (state: RootState) => state.translations
  );
  const [isEditingBillingAddress, setIsEditingBillingAddress] =
    React.useState<boolean>(false);

  const handleEditClick = () => {
    setIsEditingBillingAddress((prevState) => !prevState);
  };

  if (createPaygTranslationPayment.loading) {
    return <LoadingSpinner />;
  } else if (createPaygTranslationPayment.success === false) {
    return (
      <Typography variant='h6' sx={{ color: 'red' }}>
        {t('Oops! Something went wrong.')}
      </Typography>
    );
  }

  if (!paygTranslationPayment) {
    return (
      <Typography variant='h6' sx={{ color: 'red' }}>
        {t('Payment information is missing.')}
      </Typography>
    );
  }

  return (
    <StyledContainer>
      {!user?.billingAddress || isEditingBillingAddress ? (
        <div style={{ margin: '1rem' }}>
          <AddressForm
            t={t}
            setIsEditingBillingAddress={setIsEditingBillingAddress}
            goBackToQuoteStep={goBackToQuoteStep}
            translationQuoteBillingAddress={user?.billingAddress}
          />
        </div>
      ) : (
        <>
          <DisplayBillingAddress
            billingAddress={user.billingAddress}
            onClick={handleEditClick}
            t={t}
          />

          <Divider orientation='horizontal' />

          <CheckoutSummary
            subTotal={paygTranslationPayment.stripePayment.subTotal}
            total={paygTranslationPayment.stripePayment.amount}
            taxes={paygTranslationPayment.stripePayment.taxes}
            currency={paygTranslationPayment.stripePayment.currency}
          />

          <Divider orientation='horizontal' />

          <StripeCard t={t} completeStep={completeStep} />
        </>
      )}
    </StyledContainer>
  );
};

export default PaymentInfo;
