import React, { useState, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';
import {
  AppBar,
  Box,
  IconButton,
  Typography,
  useTheme,
  useMediaQuery,
  Drawer,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import GoogleIcon from '@mui/icons-material/Google';
import LanguageIcon from '@mui/icons-material/Language';

import {
  primaryColor2,
  backgroundColor1,
  backgroundColor2,
  textColorPrimary,
  maxPageWidth,
  primaryColor1,
  FlexRow,
  primaryColor2Darkened,
} from '../styles/constants';
import { RootState } from '../store';
import { updateUserLanguageRequest } from '../store/actions/userActions';

const StyledPageWidthContainer = styled.div`
  width: ${maxPageWidth}px;
`;

const Logo = () => (
  <img src='/tbt-thumbnail.png' alt='Trash Bandit - A.I Translation Services' />
);

const MenuItem = ({
  label,
  link,
  isFirst,
  isVertical,
  textColor,
  icon,
  onClick,
}: {
  label: string;
  link?: string;
  isFirst: boolean;
  isVertical: boolean;
  textColor?: string;
  icon?: any;
  onClick?: () => void;
}) => {
  const navigate = useNavigate();

  return (
    <>
      {link && !onClick && (
        <Link to={link} style={{ textDecoration: 'none', width: '100%' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: isVertical ? 'column' : 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: isVertical ? '100%' : 'auto',
              borderTop: `${
                isVertical && !isFirst ? `1px solid ${primaryColor2}` : null
              }`,
              paddingLeft: isVertical ? '0.5rem' : '',
            }}
          >
            {icon && icon}
            <Typography
              sx={{
                cursor: 'pointer !important',
                width: '100%',
                color: textColor ? textColor : textColorPrimary,
                borderLeft:
                  !isFirst && !isVertical ? `1px solid ${primaryColor2}` : null,
                paddingLeft: !isVertical ? '0.5rem' : '',
                paddingRight: !isVertical ? '0.5rem' : '',
                '&:hover': { backgroundColor: `${backgroundColor1}` },
                paddingBottom: isVertical ? '0.5rem' : null,
                paddingTop: isVertical ? '0.5rem' : null,
                fontWeight: textColor ? 'bold' : 'normal',
              }}
            >
              {label}
            </Typography>
          </div>
        </Link>
      )}
      {onClick && !link && (
        <div
          onClick={() => navigate('/create-translation')}
          style={{
            display: 'flex',
            flexDirection: isVertical ? 'column' : 'row',
            justifyContent: 'center',
            alignItems: 'center',
            width: isVertical ? '100%' : 'auto',
            borderTop: `${
              isVertical && !isFirst ? `1px solid ${primaryColor2}` : null
            }`,
            paddingLeft: isVertical ? '0.5rem' : '',
          }}
        >
          {icon && icon}
          <Typography
            sx={{
              cursor: 'pointer !important',
              width: '100%',
              color: textColor ? textColor : textColorPrimary,
              borderLeft:
                !isFirst && !isVertical ? `1px solid ${primaryColor2}` : null,
              paddingLeft: !isVertical ? '0.5rem' : '',
              paddingRight: !isVertical ? '0.5rem' : '',
              '&:hover': { backgroundColor: `${backgroundColor1}` },
              paddingBottom: isVertical ? '0.5rem' : null,
              paddingTop: isVertical ? '0.5rem' : null,
              fontWeight: textColor ? 'bold' : 'normal',
            }}
          >
            {label}
          </Typography>
        </div>
      )}
    </>
  );
};

interface AdditionalStylesProps {
  addStyles?: string;
}

const LanguageChangeDiv = styled.div<AdditionalStylesProps>`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  ${(props) => props.addStyles}
`;

const LanguageButton = ({
  onClick,
  language,
  addStyles,
}: {
  onClick: () => void;
  language: 'en' | 'fr';
  addStyles?: string;
}) => (
  <LanguageChangeDiv addStyles={addStyles} onClick={onClick}>
    <FlexRow>
      <LanguageIcon sx={{ color: primaryColor1, marginRight: '0.25rem' }} />
      <Typography variant='body1' sx={{ color: primaryColor1 }}>
        {language}
      </Typography>
    </FlexRow>
  </LanguageChangeDiv>
);

interface NavbarProps {
  handleGoogleLogin: () => void;
}

type Language = 'en' | 'fr';

const Navbar = forwardRef((props: NavbarProps, ref) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();
  const { user, token } = useSelector((state: RootState) => state.user);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const isHomePage = location.pathname === '/';
  const userIsAuthenticated = user && token;

  const toggleLanguage = () => {
    if (i18n.language === 'fr') {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('fr');
    }
    dispatch(updateUserLanguageRequest(i18n.language as Language));
  };

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }
      setDrawerOpen(open);
    };

  const renderMenuHorizontalItems = () => {
    return (
      <Box
        sx={{ display: 'flex', alignItems: 'center', paddingRight: '0.5rem' }}
      >
        {userIsAuthenticated && (
          <MenuItem
            label={t('Home')}
            link={'/home'}
            isFirst={true}
            isVertical={false}
          />
        )}

        {userIsAuthenticated && (
          <MenuItem
            label={t('Account')}
            link='/account'
            isFirst={false}
            isVertical={false}
          />
        )}

        {userIsAuthenticated && (
          <MenuItem
            label={t('Logout')}
            link='/logout'
            isFirst={false}
            isVertical={false}
          />
        )}

        {isHomePage && (
          <Button
            variant='contained'
            startIcon={user && token ? null : <GoogleIcon />}
            sx={{
              marginLeft: '1rem',
              backgroundColor: primaryColor2,
              '&:hover': {
                backgroundColor: primaryColor2Darkened,
              },
              minWidth: 'fit-content',
            }}
            onClick={() =>
              userIsAuthenticated
                ? navigate('/create-translation')
                : navigate('/sign-in')
            }
          >
            {userIsAuthenticated ? t('Get Started') : t('SIGN IN')}
          </Button>
        )}
      </Box>
    );
  };

  const renderMenuVerticalItems = () => (
    <div style={{ marginTop: '3rem' }}>
      {!(user && token) && (
        <div
          onClick={() =>
            userIsAuthenticated
              ? navigate('/create-translation')
              : navigate('/sign-in')
          }
          style={{
            color: primaryColor2,
            marginLeft: '0.45rem',
            marginBottom: '1rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <GoogleIcon />{' '}
          <div style={{ marginLeft: '0.5rem' }}>{t('SIGN IN')}</div>
        </div>
      )}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          minWidth: '9rem',
        }}
      >
        {userIsAuthenticated && (
          <MenuItem
            textColor={primaryColor2}
            label={t('Get Started')}
            link='/create-translation'
            isFirst={true}
            isVertical={true}
          />
        )}

        {userIsAuthenticated && (
          <MenuItem
            label={t('Home')}
            link={'/home'}
            isFirst={true}
            isVertical={true}
          />
        )}

        {userIsAuthenticated && (
          <MenuItem
            label={t('Account')}
            link='/account'
            isFirst={false}
            isVertical={true}
          />
        )}

        {userIsAuthenticated && (
          <MenuItem
            label={t('Logout')}
            link={'/logout'}
            isFirst={false}
            isVertical={true}
          />
        )}
      </Box>
    </div>
  );

  return (
    <AppBar position='sticky' sx={{ backgroundColor: backgroundColor2 }}>
      <div
        ref={ref as any}
        style={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <StyledPageWidthContainer>
          <Box
            sx={{
              width: '100%',
              margin: '0 auto',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingTop: '0.5rem',
              paddingBottom: '0.5rem',
              maxHeight: '5rem',
            }}
          >
            <div style={{ paddingLeft: '0.5rem' }}>
              <Link to={'/'}>
                <Logo />
              </Link>
            </div>

            {!isMobile && (
              <FlexRow>
                <LanguageButton
                  // @ts-ignore
                  onClick={() => toggleLanguage()}
                  language={i18n.language as any}
                  addStyles='margin-right: 1rem;'
                />
                {renderMenuHorizontalItems()}
              </FlexRow>
            )}

            {/* Hamburger Menu - shown only on smaller screens */}
            {isMobile && (
              <nav>
                <IconButton
                  sx={{ marginRight: '1rem' }}
                  onClick={() => setDrawerOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
                <Drawer
                  anchor='right'
                  open={drawerOpen}
                  onClose={toggleDrawer(false)}
                >
                  {renderMenuVerticalItems()}
                  <LanguageButton
                    onClick={() => toggleLanguage()}
                    language={i18n.language as any}
                    addStyles='margin-top: 2rem; margin-left: 0.25rem;'
                  />
                </Drawer>
              </nav>
            )}
          </Box>
        </StyledPageWidthContainer>
      </div>
    </AppBar>
  );
});
export default Navbar;
