import { put, takeLatest } from 'redux-saga/effects';

import {
  TranslationActionTypes,
  fetchTargetLanguagesSuccess,
  createTranslationSuccess,
  TranslationAction,
  fetchTranslationsSuccess,
  fetchTranslationsFailure,
  createPaygTranslationPaymentSuccess,
  deleteStripePaymentFailure,
  createPaygTranslationPaymentFailure,
  updateTranslationPaymentStatusSuccess,
  updateTranslationPaymentStatusFailure,
  createTranslationQuoteFailure,
  deletePaygTranslationPaymentSuccess,
} from '../actions/translationActions';

import {
  fetchTargetLanguages,
  createTranslation,
  fetchTranslations,
  deletePaygTranslationStripePayment,
  createPaygTranslationPayment,
  updateTranslationPayment,
} from '../api/translationApi';
import { callGenerator } from './sagaUtils';
import { ERROR_CREDENTIALS_OR_TOKEN_EXPIRED } from './constants';

function* fetchUserTranslationsSaga() {
  try {
    const { data: translations } = yield* callGenerator(fetchTranslations);
    yield put(fetchTranslationsSuccess(translations));
  } catch (error: any) {
    if (error.response.data) {
      if (error.response.data.detail) {
        const { detail } = error.response.data;

        if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
          yield put({ type: 'LOGOUT' });
        } else {
          yield put(fetchTranslationsFailure({ errorMsg: detail }));
        }
      }
    }
  }
}

function* fetchTargetLanguagesSaga() {
  try {
    const { data: targetLanguages } = yield* callGenerator(
      fetchTargetLanguages
    );
    yield put(fetchTargetLanguagesSuccess(targetLanguages));
  } catch (error: any) {
    if (error.response.data) {
      const { detail } = error.response.data;

      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
  }
}

function* createTranslationSaga(action: TranslationAction) {
  try {
    const { data } = yield* callGenerator(
      createTranslation,
      action.payload.formData
      //      token
    );
    yield put(createTranslationSuccess(data));
    // Fetch all translations
  } catch (error: any) {
    let errorMessage = 'An error occurred';
    if (error.response?.data?.detail) {
      errorMessage = error.response.data.detail;
    }
    yield put(createTranslationQuoteFailure(errorMessage));
    // Error handling
    if (error.response?.data?.detail) {
      const { detail } = error.response.data;
      if (ERROR_CREDENTIALS_OR_TOKEN_EXPIRED === detail) {
        yield put({ type: 'LOGOUT' });
      }
    }
    // Optionally dispatch a failure action
  }
}

function* deletePaygTranslationStripePaymentSaga(action: TranslationAction) {
  try {
    yield* callGenerator(
      deletePaygTranslationStripePayment,
      action.payload.translationId,
      action.payload.stripePaymentId
    );
    yield put(deletePaygTranslationPaymentSuccess());
  } catch (error) {
    yield put(deleteStripePaymentFailure());
  }
}

function* createPaygTranslationPaymentSaga(action: TranslationAction) {
  try {
    const { data } = yield* callGenerator(
      createPaygTranslationPayment,
      action.payload.translationId
    );
    yield put(createPaygTranslationPaymentSuccess(data));
  } catch (error) {
    yield put(createPaygTranslationPaymentFailure());
  }
}

function* updateTranslationPaymentStatusSaga(action: TranslationAction) {
  try {
    yield* callGenerator(
      updateTranslationPayment,
      action.payload.paymentId,
      action.payload.paymentStatus
    );
    yield put(updateTranslationPaymentStatusSuccess());
    action.payload.callback();
  } catch (error) {
    yield put(updateTranslationPaymentStatusFailure());
  }
}

export default function* rootSaga(): Generator {
  yield takeLatest(
    TranslationActionTypes.FETCH_TARGET_LANGUAGES_REQUEST,
    fetchTargetLanguagesSaga
  );
  yield takeLatest(
    TranslationActionTypes.CREATE_TRANSLATION_REQUEST,
    createTranslationSaga
  );
  yield takeLatest(
    TranslationActionTypes.FETCH_TRANSLATIONS_REQUEST,
    fetchUserTranslationsSaga
  );
  yield takeLatest(
    TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_REQUEST,
    deletePaygTranslationStripePaymentSaga
  );
  yield takeLatest(
    TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_REQUEST,
    createPaygTranslationPaymentSaga
  );
  yield takeLatest(
    TranslationActionTypes.UPDATE_TRANSLATION_PAYMENT_STATUS,
    updateTranslationPaymentStatusSaga
  );
}
