import { useTranslation } from 'react-i18next';
import { Typography, Button, Box } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';

import {
  primaryColor1,
  backgroundColor1,
  primaryColor2,
  primaryColor2Darkened,
} from '../../styles/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { useNavigate } from 'react-router-dom';
import useWindowSize from '../../hooks/useScreenSize';

interface CallToActionProps {
  handleGoogleLogin: () => void;
}

const CallToAction = ({ handleGoogleLogin }: CallToActionProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const windowSize = useWindowSize();

  const signInMessage =
    windowSize.width < 500 ? 'SIGN IN' : 'SIGN IN WITH GOOGLE';

  const user = useSelector((state: RootState) => state.user.user);

  return (
    <Box
      sx={{
        backgroundColor: 'inherit',
        textAlign: 'center',
        padding: '40px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Typography
        variant='h5'
        sx={{
          color: primaryColor1,
          fontWeight: 'bold',
          fontSize: '24px',
          marginBottom: '20px',
        }}
      >
        {t('AI Powered. Human Approved.')}
      </Typography>
      <Button
        variant='contained'
        startIcon={user ? null : <GoogleIcon />}
        sx={{
          fontSize: '16px',
          fontWeight: 'bold',
          backgroundColor: primaryColor2,
          color: backgroundColor1,
          padding: '10px 20px',
          borderRadius: '4px',
          '&:hover': {
            backgroundColor: primaryColor2Darkened,
          },
        }}
        size='large'
        onClick={() =>
          user ? navigate('/create-translation') : navigate('/sign-in')
        }
      >
        {user ? t('Get Started') : t(signInMessage)}
      </Button>
    </Box>
  );
};

export default CallToAction;
