import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import EnterpriseLoginForm from 'src/forms/enterprise/EnterpriseLoginForm';
import {
  primaryColor1,
  AuthPageContainer,
  errorColor,
} from 'src/styles/constants';
import { RootState } from 'src/store';
import { useTranslation } from 'react-i18next';

const StyledLink = styled(Link)`
  color: ${primaryColor1};
  text-decoration: none;
  margin-top: 1rem;
  &:hover {
    text-decoration: underline;
  }
`;

const EnterpriseLoginPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { success } = useSelector((state: RootState) => state.user.login);
  const token = useSelector((state: RootState) => state.user.token);

  useEffect(() => {
    if (token) {
      navigate('/home');
    }
  }, [navigate, token]);

  return (
    <AuthPageContainer>
      <EnterpriseLoginForm />
      <StyledLink to='/forgot-password'>{t('Forgot Password')}?</StyledLink>
      <br />

      {success === false && (
        <p style={{ color: errorColor }}>{t('Something went wrong')}</p>
      )}
      <br />
    </AuthPageContainer>
  );
};

export default EnterpriseLoginPage;
