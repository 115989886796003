import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import styled from '@emotion/styled';
import {
  backgroundColor2,
  textColorSecondary,
  textColorPrimary,
} from '../../styles/constants';
import { Tax } from '../../store/models/TranslationModels';

const StyledCard = styled(Card)`
  padding: 1rem;
  background-color: ${backgroundColor2};
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 8px 0;

  &.totalCost {
    font-weight: bold;
    color: ${textColorPrimary};
  }

  .label {
    color: ${textColorSecondary};
  }

  .value {
    font-weight: bold;
    text-align: right;
    color: ${textColorSecondary};
  }
`;

interface CheckoutSummaryProps {
  subTotal: number;
  total: number;
  taxes: Tax[];
  currency: string;
}

const CheckoutSummary = ({
  subTotal,
  total,
  taxes,
  currency,
}: CheckoutSummaryProps) => {
  const { t } = useTranslation();

  return (
    <StyledCard variant='outlined' sx={{ border: 'none' }}>
      <Typography variant='h6'>{t('Order Summary')}</Typography>
      <StyledRow>
        <span className='label'>{t('Price')}:</span>
        <span className='value'>{`${subTotal.toFixed(2)}`}</span>
      </StyledRow>
      {taxes.map((tax: Tax) => {
        return (
          <div key={tax.taxRate.id}>
            <StyledRow>
              <span className='label'>{`${tax.taxRate.displayName}:`}</span>
              <span className='value'>{`${tax.amount.toFixed(2)}`}</span>
            </StyledRow>
          </div>
        );
      })}
      <StyledRow className='totalCost'>
        <span>
          {t('Total')}&nbsp;({currency}):
        </span>
        <span>{`$${total.toFixed(2)}`}</span>
      </StyledRow>
    </StyledCard>
  );
};

export default CheckoutSummary;
