import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import FileUpload from './FileUpload';
import { LoadingSpinner } from '../../components';
import { RootState } from '../../store';
import { FlexColumn, errorColor } from '../../styles/constants';
import { Typography } from '@mui/material';
import { resetCreateTranslationState } from 'src/store/actions/translationActions';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface ChooseDocumentProps {
  file: File | null;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
  completeStep: () => void;
  setActiveStep: (prevActiveStep: any) => void;
}

const ChooseDocument = ({
  file,
  setFile,
  completeStep,
  setActiveStep,
}: ChooseDocumentProps) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { createTranslation, translation } = useSelector(
    (state: RootState) => state.translations
  );

  useEffect(() => {
    if (
      !createTranslation.loading &&
      createTranslation.success &&
      translation
    ) {
      completeStep();
      dispatch(resetCreateTranslationState());
      setActiveStep((prevActiveStep: any) => prevActiveStep + 1);
    }

    if (!createTranslation.loading && createTranslation.success === false) {
      setFile(null);
    }
  }, [createTranslation, translation, completeStep, setActiveStep, setFile]);

  const renderContent = () => {
    if (createTranslation.loading) {
      return (
        <FlexColumn>
          <Typography variant='body1'>
            {t('Analyzing document, do not refresh the page.')}
          </Typography>
          <Typography variant='body1'>
            {t('Analysis time depends on document size')}.
          </Typography>
          <br />
          <br />
          <br />
          <LoadingSpinner />{' '}
        </FlexColumn>
      );
    }

    return (
      <FlexColumn addStyles='min-width: 350px; max-width: 550px;'>
        <FileUpload setFile={setFile} completeStep={completeStep} />
        {createTranslation.success === false && (
          <>
            <br />
            <p style={{ color: errorColor, maxWidth: '100%' }}>
              {createTranslation.errorMessage}
            </p>
          </>
        )}
      </FlexColumn>
    );
  };

  return <StyledContainer>{renderContent()}</StyledContainer>;
};

export default ChooseDocument;
