import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from '@emotion/styled';

import { loginWithGoogleRequest } from '../../store/actions/userActions';
import { primaryColor1 } from '../../styles/constants';

const StyledProgressContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10%;
  width: 100%;
  height: 100%;
`;

const Loading = () => (
  <StyledProgressContainer>
    <CircularProgress sx={{ color: primaryColor1 }} size={200} />
  </StyledProgressContainer>
);

const GoogleAuthRedirectHandler: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { token, authError } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (token) {
      navigate('/create-translation');
    } else if (authError) {
      navigate('/sign-in');
    }
  }, [navigate, token, authError]);

  useEffect(() => {
    // Function to get the URL parameter 'code'
    const getCodeFromURL = () => {
      const queryParams = new URLSearchParams(location.search);
      return queryParams.get('code');
    };

    const code = getCodeFromURL();

    if (code) {
      // Dispatch action with the code
      dispatch(loginWithGoogleRequest({ code }));
    }
  }, [dispatch, location]);

  // Render nothing or a loading indicator
  return <Loading />;
};

export default GoogleAuthRedirectHandler;
