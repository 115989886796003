import { useTranslation } from 'react-i18next';
import styled from '@emotion/styled';
import PaygQuoteCard from './PaygQuoteCard';
import { useSelector } from 'react-redux';

import { RootState } from '../../store';
import { AcceptButton, CancelButton } from '../../styles/constants';
import { LoadingSpinner } from '../../components';
import { PaygTranslationQuote } from 'src/store/models/TranslationModels';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

interface PaygReviewQuoteProps {
  updateBillingAddress: any;
  setActiveStep: (step: number) => void;
  resetState: () => void;
  completeStep: () => void;
  paygTranslationQuote: PaygTranslationQuote;
}

const PaygReviewQuote = ({
  updateBillingAddress,
  setActiveStep,
  resetState,
  completeStep,
  paygTranslationQuote,
}: PaygReviewQuoteProps) => {
  const { t } = useTranslation();
  if (!paygTranslationQuote) {
    return (
      <StyledContainer>
        <p style={{ color: 'red' }}>
          {t('Missing PAYG Translation Quote data')}
        </p>
      </StyledContainer>
    );
  }

  return updateBillingAddress.loading ? (
    <LoadingSpinner />
  ) : (
    <StyledContainer>
      <br />
      <>
        <PaygQuoteCard paygTranslationQuote={paygTranslationQuote} />
        <br />
        <br />
        <br />
        <ButtonRow>
          <CancelButton
            onClick={() => {
              setActiveStep(0);
              resetState();
            }}
          >
            {t('Cancel')}
          </CancelButton>
          <AcceptButton
            onClick={() => {
              completeStep();
            }}
          >
            {t('Accept')}
          </AcceptButton>
        </ButtonRow>
      </>
    </StyledContainer>
  );
};

interface ReviewQuoteProps {
  completeStep: () => void;
  setActiveStep: (step: number) => void;
  resetState: () => void;
  paygTranslationQuote: PaygTranslationQuote;
}

const ReviewQuote = ({
  completeStep,
  setActiveStep,
  resetState,
  paygTranslationQuote,
}: ReviewQuoteProps) => {
  const { updateBillingAddress } = useSelector(
    (state: RootState) => state.user
  );

  // TODO check if they have a monthly subscription with available pages
  return (
    <PaygReviewQuote
      paygTranslationQuote={paygTranslationQuote}
      updateBillingAddress={updateBillingAddress}
      completeStep={completeStep}
      setActiveStep={setActiveStep}
      resetState={resetState}
    />
  );
};

export default ReviewQuote;
