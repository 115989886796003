import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useFormik } from 'formik';
import styled from '@emotion/styled';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { forgotPasswordRequest } from '../../store/actions/userActions';
import { FlexRow, AcceptButton, primaryColor1 } from '../../styles/constants';
import { TextField, Typography } from '@mui/material';

const validationSchema = yup.object({
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
});

const StyledTextField = styled(TextField)`
  margin: 0.5rem 0;
`;

const StyledLink = styled(Link)`
  &:hover {
    opacity: 0.8;
  }
`;

const ForgotPasswordForm = ({ loading }: { loading: boolean }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: ({ email }) => {
      console.log('submitting forgot password');
      dispatch(forgotPasswordRequest({ email }));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Typography
        variant='h4'
        sx={{ color: primaryColor1, marginBottom: '2rem' }}
      >
        {t('Forgot Password')}
      </Typography>
      <StyledTextField
        fullWidth
        id='email'
        name='email'
        label={t('Email')}
        value={formik.values.email}
        onChange={formik.handleChange}
        error={formik.touched.email && Boolean(formik.errors.email)}
        helperText={formik.touched.email && t(formik.errors.email as string)}
        InputLabelProps={{ shrink: true }}
      />
      <AcceptButton
        disabled={loading}
        variant='contained'
        fullWidth
        type='submit'
        data-testid='forgot-password-form-submit-button'
      >
        {t('Submit')}
      </AcceptButton>
      <br />
      <br />
      <FlexRow addStyles='justify-content: flex-end;'>
        <StyledLink to='/sign-in'>{t('Back to Login')}</StyledLink>
      </FlexRow>
    </form>
  );
};

export default ForgotPasswordForm;
