import { useTranslation } from 'react-i18next';
import { Typography, Button, Box } from '@mui/material';
import styled from '@emotion/styled';

import {
  primaryColor1,
  backgroundColor1,
  primaryColor2,
  primaryColor2Darkened,
} from '../../styles/constants';
import useWindowSize from '../../hooks/useScreenSize';
import { forwardRef } from 'react';

const StyledTypography = styled(Typography)`
  color: ${primaryColor1};
  font-size: 2rem;
  margin-bottom: 20px;

  /* Media queries for responsive font sizes */
  @media (max-width: 1200px) {
    font-size: 1.75rem;
  }

  @media (max-width: 900px) {
    font-size: 1.5rem;
  }

  @media (max-width: 600px) {
    font-size: 1.25rem;
  }
`;

// Create a StyledBox component to replace the one below
const StyledBox = styled(Box)`
  background-color: inherit;
  text-align: center;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// Change button below to a StyledButton
const StyledButton = styled(Button)<{ minButtonWidth?: number }>`
  font-size: 1rem;
  font-weight: bold;
  background-color: ${primaryColor2};
  color: ${backgroundColor1};
  padding: 10px 20px;
  border-radius: 4px;

  min-width: ${({ minButtonWidth }) =>
    minButtonWidth ? `${minButtonWidth}px` : 'auto'};

  &:hover {
    background-color: ${primaryColor2Darkened};
  }
`;

interface SignInOptionProps {
  title: string;
  callBack: () => void;
  buttonName: string;
  buttonNameShort: string;
  minButtonWidth?: number;
  icon?: JSX.Element;
}

const SignInOption = forwardRef(
  (
    {
      title,
      icon,
      buttonName,
      buttonNameShort,
      minButtonWidth,
      callBack,
    }: SignInOptionProps,
    ref
  ) => {
    const { t } = useTranslation();

    const windowSize = useWindowSize();

    const signInMessage = windowSize.width < 700 ? buttonNameShort : buttonName;

    return (
      <StyledBox>
        <StyledTypography variant='h5'>{t(title)}</StyledTypography>
        <br />
        <br />
        <StyledButton
          ref={ref as any}
          minButtonWidth={minButtonWidth}
          variant='contained'
          startIcon={icon ? icon : null}
          onClick={() => callBack()}
        >
          {t(signInMessage)}
        </StyledButton>
      </StyledBox>
    );
  }
);

export default SignInOption;
