import React, { useEffect } from 'react';
import { Container, Typography, Paper } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { acceptTermsOfServiceRequest } from '../../store/actions/userActions';
import { AcceptButton } from '../../styles/constants';
import { LoadingSpinner } from '../../components';
import { RootState } from '../../store';

const TermsOfServicePage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { user } = useSelector((state: RootState) => state.user);

  const { loading, success } = useSelector(
    (state: RootState) => state.user.updateTermsOfService
  );

  useEffect(() => {
    if (success === true) navigate('/create-translation');
  }, [success, navigate]);

  return (
    <Container component='main' maxWidth='md' sx={{ pb: 8, mb: 0, mt: 8 }}>
      {loading ? (
        <LoadingSpinner size='xxl' />
      ) : (
        <>
          <Paper elevation={3} sx={{ p: 3 }}>
            <Typography variant='h4' gutterBottom>
              Terms of Service
            </Typography>
            <Typography variant='h6' gutterBottom>
              Effective Date: Jan 23rd, 2025
            </Typography>
            <Typography paragraph>
              Welcome to Trash Bandit Translations, a service provided by
              16228356 Canada Inc. ("Company", "we", "our", or "us"). These
              Terms of Service ("Terms") govern your access to and use of our
              services, so please read them carefully.
            </Typography>
            {/* Repeat the pattern for each section */}
            <Typography variant='h5' gutterBottom>
              Acceptance of Terms
            </Typography>
            <Typography paragraph>
              By accessing or using our services, you confirm that you have
              read, understood, and agreed to be bound by these Terms. If you do
              not agree with any part of these Terms, you may not use our
              services.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Description of the Service
            </Typography>
            <Typography paragraph>
              We provide AI-driven translation services and other related
              products (collectively, "Services") designed to enhance your
              productivity and global communication capabilities. Our services
              leverage advanced AI technology to deliver fast and accurate
              translations, with plans to introduce additional AI-powered tools
              and features in the future.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Intellectual Property Rights
            </Typography>
            <Typography paragraph>
              All intellectual property rights in the Services, including
              software, trademarks, and content provided by us, are owned by
              16228356 Canada Inc. or our licensors. We grant you a limited,
              non-exclusive, non-transferable license to access and use the
              Services for your personal or business use in accordance with
              these Terms.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Translation Services and Accuracy
            </Typography>
            <Typography paragraph>
              Unless explicitly stated otherwise, translations provided through
              our Services are generated by artificial intelligence and are not
              reviewed by human translators. While we use industry-leading AI
              technology and maintain high quality standards, machine
              translation may occasionally contain errors or imperfections.
              Users are responsible for reviewing and validating translations
              for their specific needs, particularly for critical or sensitive
              content. We strongly recommend having translations independently
              verified for legal, medical, or other specialized documents where
              precision is crucial.
            </Typography>

            <Typography variant='h5' gutterBottom>
              AI-Generated Content
            </Typography>
            <Typography paragraph>
              Our Services produce content generated through AI algorithms
              ("AI-Generated Content"), including but not limited to
              translations. While we employ advanced technology and quality
              controls, we do not guarantee that AI-Generated Content will be
              error-free, complete, or suitable for your specific purposes. You
              acknowledge that AI technology has inherent limitations and agree
              to use AI-Generated Content at your own risk and discretion. We
              recommend implementing appropriate review processes based on your
              specific use case and requirements.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Prohibited Uses
            </Typography>
            <Typography paragraph>
              You may not use our Services for any unlawful purpose, to solicit
              others to perform or participate in any unlawful acts, to violate
              any international regulations, or to infringe upon our
              intellectual property rights or the rights of others including
              Copyright infringements.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Privacy and Data Use
            </Typography>
            <Typography paragraph>
              Your privacy is important to us. Our Privacy Policy, which is
              incorporated by reference into these Terms, explains how we
              collect, use, and share your personal information. Please review
              our Privacy Policy to understand our practices.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Warranties and Disclaimers
            </Typography>
            <Typography paragraph>
              Our Services are provided "as is" and "as available" without any
              warranties, expressed or implied, including but not limited to the
              accuracy of AI-Generated Content. We disclaim all warranties to
              the maximum extent permitted by law.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Limitation of Liability
            </Typography>
            <Typography paragraph>
              To the maximum extent permitted by applicable law, in no event
              shall 16228356 Canada Inc. be liable to you or any third party for
              any indirect, incidental, special, consequential, or punitive
              damages, including but not limited to, damages for loss of
              profits, goodwill, use, data or other intangible losses, arising
              out of or related to your use of, or inability to use, our
              Services, regardless of whether such damages are based on
              contract, tort, warranty, statute or otherwise.
            </Typography>
            <Typography paragraph>
              Further, except as otherwise expressly agreed to in writing, our
              total cumulative liability to you or any third party arising from
              or related to the Services, whether in contract or tort or
              otherwise, shall not exceed the total amount of fees actually paid
              by you to 16228356 Canada Inc. over the 12 months prior to the
              claim giving rise to such liability. This limitation of liability
              is an essential element of the agreement between you and 16228356
              Canada Inc.
            </Typography>
            <Typography paragraph>
              Some jurisdictions do not allow the exclusion or limitation of
              liability for consequential or incidental damages, so the above
              limitations may not apply to you in such jurisdictions. In these
              cases, 16228356 Canada Inc.'s liability will be limited to the
              greatest extent permitted by law.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Modification of Terms and Services
            </Typography>
            <Typography paragraph>
              We reserve the right to modify these Terms or our Services at any
              time. We will provide notice of material changes to these Terms by
              posting the updated Terms on our website. Your continued use of
              the Services after such changes constitutes your acceptance of the
              new Terms.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Termination
            </Typography>
            <Typography paragraph>
              We may terminate or suspend your access to our Services
              immediately, without prior notice or liability, for any reason,
              including but not limited to a breach of these Terms.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Governing Law and Dispute Resolution
            </Typography>
            <Typography paragraph>
              These Terms shall be governed by and construed in accordance with
              the laws of Canada, without giving effect to any principles of
              conflicts of law. Any disputes arising from these Terms or your
              use of the Services will be resolved through binding arbitration
              in accordance with the rules of the ADR Institute of Canada
              (ADRIC) Arbitration Rules.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Feedback and Reporting Issues
            </Typography>
            <Typography paragraph>
              We welcome feedback, comments, and suggestions for improvements to
              our Services. You can submit feedback by contacting us at
              help@smart-build-ai.com. If you encounter any issues, please
              contact us so we can address them promptly.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Miscellaneous Provisions
            </Typography>
            <Typography paragraph>
              These Terms constitute the entire agreement between you and
              16228356 Canada Inc. regarding the use of our Services. Our
              failure to enforce any right or provision of these Terms will not
              be considered a waiver of those rights.
            </Typography>

            <Typography variant='h5' gutterBottom>
              Contact Us
            </Typography>
            <Typography paragraph>
              Contact us at <b>support@trash-bandit-translations.com</b> if you
              have any questions about these terms
            </Typography>

            <br />
            {user && (
              <AcceptButton
                onClick={() => dispatch(acceptTermsOfServiceRequest())}
              >
                Accept
              </AcceptButton>
            )}
          </Paper>
        </>
      )}
    </Container>
  );
};

export default TermsOfServicePage;
