import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

import reportWebVitals from './reportWebVitals';
import store from './store';
import App from './App';
import './index.css';
import './i18n/i18n';
import { ScheduledMaintenance } from './components';

const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STRIPE_PUBLIC_KEY_DEV!
    : process.env.REACT_APP_STRIPE_PUBLIC_KEY_PROD!
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

console.log('NODE_ENV:', process.env.NODE_ENV);
console.log(process.env.NODE_ENV === 'development');

const AppWrapper = () => (
  <Elements stripe={stripePromise}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID!}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </GoogleOAuthProvider>
  </Elements>
);

root.render(
  process.env.NODE_ENV === 'development' ? (
    <AppWrapper />
  ) : (
    <React.StrictMode>
      <AppWrapper />
    </React.StrictMode>
  )
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
