import React, { useEffect, useState } from 'react';
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import styled from '@emotion/styled';
import { Button, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';

import {
  backgroundColor1,
  errorColor,
  primaryColor2,
  primaryColor2Darkened,
} from '../../styles/constants';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { LoadingSpinner } from '../../components';
import api from '../../utils/api';

const LoadingOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.7); // Semi-transparent white background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10; // Ensure it's above other content
`;

const StyledContainer = styled.div`
  margin: 1rem 1rem 0 1rem;
  max-width: 250px; // Set max-width for the whole grid
`;

const StyledCardContainer = styled.div`
  background: ${backgroundColor1};
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-bottom: 10px; // Reduced bottom margin
`;

const StyledRemoveYellowBackground = styled.div`
  .StripeElement {
    width: 100%;

    &--webkit-autofill {
      background-color: transparent !important; // Unset autofill background color
    }
  }
`;

const StyledFieldsRow = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr; // Two columns of equal width
  grid-gap: 10px; // Gap between the fields
`;

const StyledFieldContainer = styled.div`
  background: ${backgroundColor1};
  padding: 10px;
  border: none;
  border-radius: 4px;

  .StripeElement {
    width: 100%;
  }
`;

const StyledNameContainer = styled.div`
  margin-top: 10px; // Added top margin
  background: ${backgroundColor1};
  padding: 10px;
  border: none;
  border-radius: 4px;

  input {
    border: none;
    background: inherit;

    &:focus {
      outline: none;
    }
  }
`;

const PayButton = styled(Button)({
  backgroundColor: primaryColor2,
  color: 'white',
  '&:hover': {
    backgroundColor: primaryColor2Darkened,
  },
  width: '100%',
});

const FIELD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#32325d',
      fontFamily: 'Arial, sans-serif',
      fontSmoothing: 'antialiased',
      fontSize: '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

interface CardElementChangeEvent {
  complete: boolean | ((prevState: boolean) => boolean);
}

interface StripeCardProps {
  completeStep: () => void;
  t: any;
}

const pollPaymentStatus = async (
  paymentId: number,
  maxAttempts = 30,
  interval = 1000
) => {
  let attempts = 0;

  const executePoll = async (
    resolve: (arg0: any) => void,
    reject: (arg0: unknown) => void
  ) => {
    try {
      const response = await api.get(`/payments/status/${paymentId}`);
      const status = response.data.status;

      if (status === 'succeeded') {
        // resolve(status);
        resolve(response.data);
      } else if (
        status === 'payment_failed' ||
        status === 'cancelled' ||
        attempts >= maxAttempts
      ) {
        // reject(status);
        reject(response.data);
      } else {
        attempts++;
        setTimeout(executePoll, interval, resolve, reject);
      }
    } catch (error) {
      reject(error);
    }
  };

  return new Promise(executePoll);
};

const StripeCard = ({ t, completeStep }: StripeCardProps) => {
  const { paygTranslationPayment } = useSelector(
    (state: RootState) => state.translations
  );
  if (!paygTranslationPayment?.stripePayment.id) {
    throw new Error('No payment ID found');
  }
  if (!paygTranslationPayment?.stripePayment.amount) {
    throw new Error('No payment amount found');
  }
  if (!paygTranslationPayment?.stripePayment.currency) {
    throw new Error('No currency info found');
  }
  if (!paygTranslationPayment?.stripePayment.clientSecret) {
    throw new Error('No stripe payment client secret found');
  }

  const [searchParams] = useSearchParams();

  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);

  const [paymentError, setPaymentError] = useState<string | undefined>();

  const [isCardNumberComplete, setCardNumberComplete] = useState(false);
  const [isCardExpiryComplete, setCardExpiryComplete] = useState(false);
  const [isCardCvcComplete, setCardCvcComplete] = useState(false);

  const [cardholderName, setCardholderName] = useState('');

  const getPaymentStatus = async () => {
    try {
      const response = await api.get(
        `/payments/status/${paygTranslationPayment.stripePayment.id}`
      );
      return response.data.status;
    } catch (error) {
      console.error('Failed to get payment status:', error);
      return 'failed';
    }
  };

  useEffect(() => {
    const polling = searchParams.get('isPolling');
    const isPolling = Boolean(polling);
    if (isPolling) {
      const idString = searchParams.get('paymentId');
      const paygTranslationPaymentId = Number(idString);

      if (!paygTranslationPaymentId) {
        throw new Error('No payment ID found');
      }

      pollPaymentStatus(paygTranslationPaymentId)
        .then(() => {
          completeStep();
        })
        .catch(() => {
          setPaymentError(t('Payment failed.'));
        });
    }
  }, [searchParams, completeStep, t]);

  const handleCardholderNameChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setCardholderName(event.target.value);
  };

  const handleCardNumberChange = (event: CardElementChangeEvent) => {
    setCardNumberComplete(event.complete);
  };

  const handleCardExpiryChange = (event: CardElementChangeEvent) => {
    setCardExpiryComplete(event.complete);
  };

  const handleCardCvcChange = (event: CardElementChangeEvent) => {
    setCardCvcComplete(event.complete);
  };

  const isPayButtonDisabled = () => {
    const bool =
      !stripe ||
      loading ||
      !isCardNumberComplete ||
      !isCardExpiryComplete ||
      !isCardCvcComplete ||
      !cardholderName;

    return bool;
  };

  const handleSubmit = async (event: { preventDefault: () => void }) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet
      return;
    }

    const cardNumberElement = elements.getElement(CardNumberElement);

    if (!cardNumberElement) {
      // TODO show the error
      // Card element is not ready or not found in the form
      console.log('Card element not found or not ready');
      return;
    }

    setLoading(true);

    try {
      // Clean up the connection when component unmounts
      const status = await getPaymentStatus();
      if (status === 'uninitialized') {
        console.error(`Payment status is not 'created': ${status}`);
        setPaymentError(t('Could not create payment. Try again later'));
        setLoading(false);
        return;
      }

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        paygTranslationPayment.stripePayment.clientSecret,
        {
          payment_method: {
            // @ts-ignore
            card: cardNumberElement,
            billing_details: {
              name: cardholderName,
            },
          },
        }
      );

      if (error) {
        // TODO throw an error but include an error boundry and toast message
        console.error('Payment failed:', error.message);
        setPaymentError(error.message);
        setLoading(false);
        return;
      }

      // TODO should I create a json db table for errors, this case would be a payment succeeds but not recorded in db
      try {
        const responseData = await pollPaymentStatus(
          paygTranslationPayment.stripePayment.id
        );
        const status = responseData.status;
        if (status === 'payment_failed') {
          setPaymentError(t('Payment failed.'));
        } else if (status === 'succeeded') {
          completeStep();
        } else {
          setPaymentError(t(`Unknown payment status: ${status}`));
        }
      } catch (error) {
        console.error('Polling payment status failed:', error);
        setPaymentError(t('Payment failed.'));
      } finally {
        setLoading(false);
      }
    } catch (error) {
      console.error(`Bottom catch, Payment failed: ${error}`);
      setPaymentError(t('Oops! Something went wrong.'));
      setLoading(false);
    }
  };

  return (
    <StyledContainer>
      {loading && (
        <LoadingOverlay>
          <LoadingSpinner size='xl' />
        </LoadingOverlay>
      )}
      <Typography sx={{ marginBottom: '1rem' }} variant='h6'>
        {t('Credit Card')}
      </Typography>
      <form onSubmit={handleSubmit}>
        <StyledCardContainer>
          <StyledRemoveYellowBackground>
            <CardNumberElement
              onChange={handleCardNumberChange}
              options={FIELD_ELEMENT_OPTIONS}
            />
          </StyledRemoveYellowBackground>
        </StyledCardContainer>
        <StyledFieldsRow>
          <StyledRemoveYellowBackground>
            <StyledFieldContainer>
              <CardExpiryElement
                onChange={handleCardExpiryChange}
                options={FIELD_ELEMENT_OPTIONS}
              />
            </StyledFieldContainer>
          </StyledRemoveYellowBackground>
          <StyledRemoveYellowBackground>
            <StyledFieldContainer>
              <CardCvcElement
                onChange={handleCardCvcChange}
                options={FIELD_ELEMENT_OPTIONS}
              />
            </StyledFieldContainer>
          </StyledRemoveYellowBackground>
        </StyledFieldsRow>
        <StyledNameContainer>
          <input
            type='text'
            placeholder={t('NAME ON CARD')}
            value={cardholderName}
            onChange={handleCardholderNameChange}
            required
            style={{ textTransform: 'uppercase' }}
          />
        </StyledNameContainer>
        <br />
        <PayButton type='submit' disabled={isPayButtonDisabled()}>
          {t('PAY')}
        </PayButton>
      </form>
      <br />
      <p style={{ color: errorColor }}>{paymentError}</p>
    </StyledContainer>
  );
};

export default StripeCard;
