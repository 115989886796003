import {
  TranslationAction,
  TranslationActionTypes,
} from '../actions/translationActions';
import {
  TargetLanguage,
  Translation,
  PaygTranslationPayment,
  PaygTranslationQuote,
} from '../models/TranslationModels';

interface TranslationState {
  targetLanguages: TargetLanguage[];
  translations: Translation[];
  paygTranslationPayment: PaygTranslationPayment | undefined;

  paygTranslationQuote: PaygTranslationQuote | undefined;

  // TODO this needs to be renamed to new or create translation
  translation: Translation | undefined;

  // TODO loading, success should always be for a specific action
  loading: boolean;
  success: boolean | undefined;

  // TODO why is this here and not in authReducer?
  enterpriseLogin: {
    loading: boolean;
    success: boolean | undefined;
  };
  createPaygTranslationPayment: {
    loading: boolean;
    success: boolean | undefined;
  };
  deletePaygTranslationPayment: {
    loading: boolean;
    success: boolean | undefined;
  };
  createTranslation: {
    loading: boolean;
    success: boolean | undefined;
    errorMessage: string | undefined;
  };
  fetchTranslations: {
    loading: boolean;
    success: boolean | undefined;
    errorMessage: string | undefined;
  };
}

const initialState: TranslationState = {
  targetLanguages: [],
  translations: [],
  paygTranslationPayment: undefined,
  paygTranslationQuote: undefined,
  enterpriseLogin: {
    loading: false,
    success: undefined,
  },
  createPaygTranslationPayment: {
    loading: false,
    success: undefined,
  },
  deletePaygTranslationPayment: {
    loading: false,
    success: undefined,
  },
  createTranslation: {
    loading: false,
    success: undefined,
    errorMessage: undefined,
  },
  fetchTranslations: {
    loading: false,
    success: undefined,
    errorMessage: undefined,
  },
  translation: undefined,
  loading: false,
  success: undefined,
};

function translationReducer(
  state = initialState,
  action: TranslationAction
): TranslationState {
  switch (action.type) {
    case TranslationActionTypes.FETCH_TARGET_LANGUAGES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case TranslationActionTypes.FETCH_TARGET_LANGUAGES_SUCCESS:
      return {
        ...state,
        targetLanguages: action.payload,
        loading: false,
        success: true,
      };

    case TranslationActionTypes.CREATE_TRANSLATION_REQUEST:
      return {
        ...state,
        createTranslation: {
          loading: true,
          success: undefined,
          errorMessage: undefined,
        },
      };

    case TranslationActionTypes.CREATE_TRANSLATION_SUCCESS:
      return {
        ...state,
        createTranslation: {
          loading: false,
          success: true,
          errorMessage: undefined,
        },
        translation: action.payload.translation,
      };

    case TranslationActionTypes.CREATE_TRANSLATION_FAILURE:
      return {
        ...state,
        createTranslation: {
          loading: false,
          success: false,
          errorMessage: action.payload,
        },
      };

    case TranslationActionTypes.RESET_CREATE_TRANSLATION_STATE:
      return {
        ...state,
        createTranslation: {
          loading: false,
          success: undefined,
          errorMessage: undefined,
        },
      };

    case TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_REQUEST:
      return {
        ...state,
        createPaygTranslationPayment: {
          ...state.paygTranslationPayment,
          loading: true,
          success: undefined,
        },
      };

    case TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_SUCCESS:
      return {
        ...state,
        createPaygTranslationPayment: {
          loading: false,
          success: true,
        },
        paygTranslationPayment: action.payload,
      };

    case TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_FAILURE:
      return {
        ...state,
        createPaygTranslationPayment: {
          loading: false,
          success: false,
        },
      };

    case TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_REQUEST:
      return {
        ...state,
        deletePaygTranslationPayment: {
          loading: true,
          success: undefined,
        },
      };
    case TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_SUCCESS:
      return {
        ...state,
        deletePaygTranslationPayment: {
          loading: false,
          success: true,
        },
      };
    case TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_FAILURE:
      return {
        ...state,
        deletePaygTranslationPayment: {
          loading: false,
          success: false,
        },
      };

    case TranslationActionTypes.FETCH_TRANSLATIONS_REQUEST:
      return {
        ...state,
        fetchTranslations: {
          loading: true,
          success: undefined,
          errorMessage: undefined,
        },
      };

    case TranslationActionTypes.FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        translations: action.payload.translations,
        fetchTranslations: {
          loading: false,
          success: true,
          errorMessage: undefined,
        },
      };

    case TranslationActionTypes.FETCH_TRANSLATIONS_FAILURE:
      return {
        ...state,
        fetchTranslations: {
          loading: false,
          success: false,
          errorMessage: action.payload.errorMessage,
        },
      };

    case TranslationActionTypes.RESET_TRANSLATION_SUCCESS:
      return {
        ...state,
        success: undefined,
      };
    case TranslationActionTypes.RESET_TRANSLATION_LOADING:
      return {
        ...state,
        loading: false,
      };

    case TranslationActionTypes.RESET_TRANSLATION_STATE:
      return {
        ...initialState,
        targetLanguages: state.targetLanguages,
      };

    case TranslationActionTypes.LOGOUT:
      return initialState;

    default:
      return state;
  }
}

export default translationReducer;
