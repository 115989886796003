import { UpdateBillingAddressPayload, User } from '../models/UserModels';

export enum AuthActionTypes {
  LOGIN_WITH_GOOGLE_REQUEST = 'LOGIN_WITH_GOOGLE_REQUEST',
  LOGIN_WITH_GOOGLE_SUCCESS = 'LOGIN_WITH_GOOGLE_SUCCESS',
  LOGIN_WITH_GOOGLE_FAILURE = 'LOGIN_WITH_GOOGLE_FAILURE',

  ACCEPT_PRIVACY_POLICY_REQUEST = 'ACCEPT_PRIVACY_POLICY_REQUEST',
  ACCEPT_PRIVACY_POLICY_SUCCESS = 'ACCEPT_PRIVACY_POLICY_SUCCESS ',
  ACCEPT_PRIVACY_POLICY_FAILURE = 'ACCEPT_PRIVACY_POLICY_FAILURE ',

  ACCEPT_TERMS_OF_SERVICE_REQUEST = 'ACCEPT_TERMS_OF_SERVICE_REQUEST',
  ACCEPT_TERMS_OF_SERVICE_SUCCESS = 'ACCEPT_TERMS_OF_SERVICE_SUCCESS',
  ACCEPT_TERMS_OF_SERVICE_FAILURE = 'ACCEPT_TERMS_OF_SERVICE_FAILURE',

  CONTACT_US_REQUEST = 'CONTACT_US_REQUEST',
  CONTACT_US_SUCCESS = 'CONTACT_US_SUCCESS',
  CONTACT_US_FAILURE = 'CONTACT_US_FAILURE',
  CONTACT_US_RESET = 'CONTACT_US_RESET',

  UPDATE_USER_LANGUAGE_REQUEST = 'UPDATE_USER_LANGUAGE_REQUEST',
  UPDATE_USER_LANGUAGE_SUCCESS = 'UPDATE_USER_LANGUAGE_SUCCESS',
  UPDATE_USER_LANGUAGE_FAILURE = 'UPDATE_USER_LANGUAGE_FAILURE',

  FETCH_USER_REQUEST = 'FETCH_USER_REQUEST',
  FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS',
  FETCH_USER_FAILURE = 'FETCH_USER_FAILURE',

  ENTERPRISE_REGISTER_USER_REQUEST = 'ENTERPRISE_REGISTER_USER_REQUEST',
  ENTERPRISE_REGISTER_USER_SUCCESS = 'ENTERPRISE_REGISTER_USER_SUCCESS',
  ENTERPRISE_REGISTER_USER_FAILURE = 'ENTERPRISE_REGISTER_USER_FAILURE',

  ENTERPRISE_LOGIN_USER_REQUEST = 'ENTERPRISE_LOGIN_USER_REQUEST',
  ENTERPRISE_LOGIN_USER_SUCCESS = 'ENTERPRISE_LOGIN_USER_SUCCESS',
  ENTERPRISE_LOGIN_USER_FAILURE = 'ENTERPRISE_LOGIN_USER_FAILURE',

  UPDATE_BILLING_ADDRESS_REQUEST = 'UPDATE_BILLING_ADDRESS_REQUEST',
  UPDATE_BILLING_ADDRESS_REQUEST_SUCCESS = 'UPDATE_BILLING_ADDRESS_REQUEST_SUCCESS ',
  UPDATE_BILLING_ADDRESS_REQUEST_FAILURE = 'UPDATE_BILLING_ADDRESS_REQUEST_FAILURE',

  CHECK_AUTH_TOKEN = 'CHECK_AUTH_TOKEN',

  RESET_AUTH_LOADING = 'RESET_AUTH_LOADING',

  RESET_LOGIN_LOADING = 'RESET_LOGIN_LOADING',
  RESET_REGISTER_LOADING = 'RESET_REGISTER_LOADING',

  FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE',
  FORGOT_PASSWORD_RESET = 'FORGOT_PASSWORD_RESET',

  LOGOUT = 'LOGOUT',

  UPDATE_USER_BILLING_ADDRESS_REQUEST = 'UPDATE_USER_BILLING_ADDRESS_REQUEST',
  UPDATE_USER_BILLING_ADDRESS_REQUEST_SUCCESS = 'UPDATE_USER_BILLING_ADDRESS_REQUEST_SUCCESS',

  NEW_PASSWORD_REQUEST = 'NEW_PASSWORD_REQUEST',
  NEW_PASSWORD_SUCCESS = 'NEW_PASSWORD_SUCCESS',
  NEW_PASSWORD_FAILURE = 'NEW_PASSWORD_FAILURE',
  NEW_PASSWORD_RESET = 'NEW_PASSWORD_RESET',
}

export interface AuthAction {
  type: AuthActionTypes;
  payload?: any;
}

export const forgotPasswordRequest = ({ email }: { email: string }) => ({
  type: AuthActionTypes.FORGOT_PASSWORD_REQUEST,
  payload: { email },
});

export const resetEnterpriseLoginLoading = () => ({
  type: AuthActionTypes.RESET_LOGIN_LOADING,
});

export const resetEnterpriseRegisterLoading = () => ({
  type: AuthActionTypes.RESET_REGISTER_LOADING,
});

export const registerEnterpriseUserRequest = ({
  email,
  activationCode,
  password,
  organizationId,
}: {
  email: string;
  activationCode: string;
  password: string;
  organizationId: number;
}) => ({
  type: AuthActionTypes.ENTERPRISE_REGISTER_USER_REQUEST,
  payload: { email, activationCode, password, organizationId },
});

// export const registerEnterpriseUserSuccess = (user: User) => ({
//   type: AuthActionTypes.ENTERPRISE_REGISTER_USER_SUCCESS,
//   payload: user,
// });

// export const registerEnterpriseUserFailure = (error: string) => ({
//   type: AuthActionTypes.ENTERPRISE_REGISTER_USER_FAILURE,
//   payload: error,
// });

export const loginEnterpriseUserRequest = ({
  email,
  password,
}: {
  email: string;
  password: string;
}) => ({
  type: AuthActionTypes.ENTERPRISE_LOGIN_USER_REQUEST,
  payload: { email, password },
});

export const logout = () => ({
  type: AuthActionTypes.LOGOUT,
});

// Login with Google is for the public, for now everything else is for the enterprise
export const loginWithGoogleRequest = ({ code }: { code: string }) => ({
  type: AuthActionTypes.LOGIN_WITH_GOOGLE_REQUEST,
  payload: { code },
});

export const loginWithGoogleSuccess = ({
  user,
  token,
}: {
  user: User;
  token: string;
}) => ({
  type: AuthActionTypes.LOGIN_WITH_GOOGLE_SUCCESS,
  payload: { user, token },
});

export const loginWithGoogleFailure = (error: string) => ({
  type: AuthActionTypes.LOGIN_WITH_GOOGLE_FAILURE,
  payload: error,
});

export const acceptPrivacyPolicyRequest = () => ({
  type: AuthActionTypes.ACCEPT_PRIVACY_POLICY_REQUEST,
});

export const acceptPrivacyPolicySuccess = (user: User) => ({
  type: AuthActionTypes.ACCEPT_PRIVACY_POLICY_SUCCESS,
  payload: user,
});

export const acceptPrivacyPolicyFailure = (error: string) => ({
  type: AuthActionTypes.ACCEPT_PRIVACY_POLICY_FAILURE,
  payload: error,
});

export const acceptTermsOfServiceRequest = () => ({
  type: AuthActionTypes.ACCEPT_TERMS_OF_SERVICE_REQUEST,
});

export const acceptTermsOfServiceSuccess = (user: User) => ({
  type: AuthActionTypes.ACCEPT_TERMS_OF_SERVICE_SUCCESS,
  payload: user,
});

export const acceptTermsOfServiceFailure = (error: string) => ({
  type: AuthActionTypes.ACCEPT_TERMS_OF_SERVICE_FAILURE,
  payload: error,
});

export const contactUsRequest = (email: string, message: string) => ({
  type: AuthActionTypes.CONTACT_US_REQUEST,
  payload: { email, message },
});

export const contactUsRequestSuccess = () => ({
  type: AuthActionTypes.CONTACT_US_SUCCESS,
});

export const contactUsRequestFailure = (error: string) => ({
  type: AuthActionTypes.CONTACT_US_FAILURE,
  payload: error,
});

export const updateUserLanguageRequest = (language: 'en' | 'fr') => ({
  type: AuthActionTypes.UPDATE_USER_LANGUAGE_REQUEST,
  payload: language,
});

export const updateUserLanguageSuccess = (user: User) => ({
  type: AuthActionTypes.UPDATE_USER_LANGUAGE_SUCCESS,
  payload: user,
});

export const updateUserLanguageFailure = (error: string) => ({
  type: AuthActionTypes.UPDATE_USER_LANGUAGE_FAILURE,
  payload: error,
});

export const fetchUser = () => ({
  type: AuthActionTypes.FETCH_USER_REQUEST,
});

export const fetchUserSuccess = ({
  user,
  token,
}: {
  user: User;
  token: string;
}) => ({
  type: AuthActionTypes.FETCH_USER_SUCCESS,
  payload: { user, token },
});

export const checkAuthToken = ({ token }: { token: string }) => ({
  type: AuthActionTypes.CHECK_AUTH_TOKEN,
  payload: { token },
});

export const updateBillingAddress = (
  payload: UpdateBillingAddressPayload,
  setIsEditingBillingAddress: (isEditing: boolean) => void
) => ({
  type: AuthActionTypes.UPDATE_BILLING_ADDRESS_REQUEST,
  payload: { ...payload, callback: setIsEditingBillingAddress },
});

export const updateBillingAddressSuccess = () => ({
  type: AuthActionTypes.UPDATE_BILLING_ADDRESS_REQUEST_SUCCESS,
});

export const resetForgotPasswordState = () => ({
  type: AuthActionTypes.FORGOT_PASSWORD_RESET,
});

export const newPasswordRequest = (
  email: string,
  token: string,
  password: string
) => ({
  type: AuthActionTypes.NEW_PASSWORD_REQUEST,
  payload: { email, token, password },
});

export const resetNewPasswordState = () => ({
  type: AuthActionTypes.NEW_PASSWORD_RESET,
});
