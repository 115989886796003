import { AuthAction, AuthActionTypes } from '../actions/userActions';
import { User } from '../models/UserModels';
import {
  getToken,
  getUser,
  setToken,
  setUser,
  removeToken,
  removeUser,
} from '../../utils/auth';

interface AuthState {
  user: User | null;
  token: string | null;
  loading: boolean;
  success: boolean | undefined;
  authError: string | undefined;
  updateBillingAddress: {
    loading: boolean;
    success: boolean | undefined;
  };
  updateLanguage: {
    loading: boolean;
    success: boolean;
    error: string | undefined;
  };
  userAuthCheck: {
    loading: boolean;
    success: boolean;
  };
  updatePrivacyPolicy: {
    loading: boolean;
    success: boolean | undefined;
  };
  updateTermsOfService: {
    loading: boolean;
    success: boolean | undefined;
  };
  login: {
    loading: boolean;
    success: boolean | undefined;
  };
  register: {
    loading: boolean;
    success: boolean | undefined;
  };
  contactUs: {
    loading: boolean;
    success: boolean | undefined;
  };
  forgotPassword: {
    loading: boolean;
    success: boolean | undefined;
  };
  newPassword: {
    loading: boolean;
    success: boolean | undefined;
  };
}
const localStorageToken = getToken();
const localStorageUser = getUser();

const initialState: AuthState = {
  user: localStorageUser,
  authError: undefined,
  userAuthCheck: {
    loading: false,
    success: false, // If user is authenticated
  },
  updateLanguage: {
    loading: false,
    success: false,
    error: undefined,
  },
  updateBillingAddress: {
    loading: false,
    success: undefined,
  },
  token: localStorageToken,
  loading: false,
  success: undefined,
  updatePrivacyPolicy: {
    loading: false,
    success: undefined,
  },
  updateTermsOfService: {
    loading: false,
    success: undefined,
  },
  login: {
    loading: false,
    success: undefined,
  },
  register: {
    loading: false,
    success: undefined,
  },
  contactUs: {
    loading: false,
    success: undefined,
  },
  forgotPassword: {
    loading: false,
    success: undefined,
  },
  newPassword: {
    loading: false,
    success: undefined,
  },
};

function authReducer(state = initialState, action: AuthAction): AuthState {
  switch (action.type) {
    case AuthActionTypes.ENTERPRISE_REGISTER_USER_REQUEST:
      removeToken();
      removeUser();

      return {
        ...state,
        token: null,
        user: null,
        register: {
          loading: true,
          success: undefined,
        },
      };
    case AuthActionTypes.ENTERPRISE_REGISTER_USER_SUCCESS:
      return {
        ...state,
        register: {
          loading: false,
          success: true,
        },
      };
    case AuthActionTypes.ENTERPRISE_REGISTER_USER_FAILURE:
      return {
        ...state,
        register: {
          loading: false,
          success: false,
        },
      };
    case AuthActionTypes.ENTERPRISE_LOGIN_USER_REQUEST:
      return {
        ...state,
        login: {
          loading: true,
          success: undefined,
        },
      };
    case AuthActionTypes.ENTERPRISE_LOGIN_USER_SUCCESS:
      setToken(action.payload.token);
      setUser(action.payload.user as User);

      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user as User,
        login: {
          loading: false,
          success: true,
        },
      };
    case AuthActionTypes.ENTERPRISE_LOGIN_USER_FAILURE:
      return {
        ...state,
        login: {
          loading: false,
          success: false,
        },
      };
    case AuthActionTypes.LOGIN_WITH_GOOGLE_SUCCESS:
    case AuthActionTypes.FETCH_USER_SUCCESS:
      setToken(action.payload.token);
      setUser(action.payload.user as User);

      return {
        ...state,
        token: action.payload.token,
        user: action.payload.user as User,
        login: {
          loading: false,
          success: true,
        },
      };

    case AuthActionTypes.LOGIN_WITH_GOOGLE_FAILURE:
      removeToken();
      removeUser();

      return {
        ...state,
        token: null,
        user: null,
        authError: action.payload,
        login: {
          loading: false,
          success: false,
        },
      };
    case AuthActionTypes.ACCEPT_PRIVACY_POLICY_REQUEST:
      return {
        ...state,
        updatePrivacyPolicy: {
          loading: true,
          success: undefined,
        },
      };

    case AuthActionTypes.ACCEPT_PRIVACY_POLICY_SUCCESS:
      removeUser();
      setUser(action.payload as User);

      return {
        ...state,
        user: action.payload,
        updatePrivacyPolicy: {
          loading: false,
          success: true,
        },
      };

    case AuthActionTypes.ACCEPT_PRIVACY_POLICY_FAILURE:
      return {
        ...state,
        updatePrivacyPolicy: {
          loading: false,
          success: false,
        },
      };

    case AuthActionTypes.ACCEPT_TERMS_OF_SERVICE_REQUEST:
      return {
        ...state,
        updateTermsOfService: {
          loading: true,
          success: undefined,
        },
      };

    case AuthActionTypes.ACCEPT_TERMS_OF_SERVICE_SUCCESS:
      removeUser();
      setUser(action.payload as User);

      return {
        ...state,
        user: action.payload,
        updateTermsOfService: {
          loading: false,
          success: true,
        },
      };

    case AuthActionTypes.ACCEPT_TERMS_OF_SERVICE_FAILURE:
      return {
        ...state,
        updateTermsOfService: {
          loading: false,
          success: false,
        },
      };

    case AuthActionTypes.RESET_AUTH_LOADING:
      return {
        ...state,
        login: {
          loading: false,
          success: undefined,
        },
      };

    case AuthActionTypes.CONTACT_US_REQUEST:
      return {
        ...state,
        contactUs: {
          loading: true,
          success: undefined,
        },
      };

    case AuthActionTypes.CONTACT_US_RESET:
      return {
        ...state,
        contactUs: {
          loading: false,
          success: undefined,
        },
      };

    case AuthActionTypes.CONTACT_US_SUCCESS:
      return {
        ...state,
        contactUs: {
          loading: false,
          success: true,
        },
      };

    case AuthActionTypes.CONTACT_US_FAILURE:
      return {
        ...state,
        contactUs: {
          loading: false,
          success: false,
        },
      };

    case AuthActionTypes.UPDATE_USER_LANGUAGE_REQUEST:
      return {
        ...state,
        updateLanguage: {
          loading: true,
          success: false,
          error: undefined,
        },
      };

    case AuthActionTypes.UPDATE_USER_LANGUAGE_SUCCESS:
      setUser(action.payload as User);

      return {
        ...state,
        user: action.payload,
        updateLanguage: {
          loading: false,
          success: true,
          error: undefined,
        },
      };

    case AuthActionTypes.UPDATE_USER_LANGUAGE_FAILURE:
      return {
        ...state,
        updateLanguage: {
          loading: false,
          success: false,
          error: action.payload,
        },
      };

    case AuthActionTypes.LOGOUT:
      removeToken();
      removeUser();

      window.location.href = '/';

      return {
        ...state,
        user: null,
        token: null,
      };

    case AuthActionTypes.UPDATE_USER_BILLING_ADDRESS_REQUEST:
      return {
        ...state,
        updateBillingAddress: {
          loading: true,
          success: undefined,
        },
      };
    case AuthActionTypes.UPDATE_USER_BILLING_ADDRESS_REQUEST_SUCCESS:
      return {
        ...state,
        updateBillingAddress: {
          loading: false,
          success: true,
        },
      };

    case AuthActionTypes.FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        forgotPassword: {
          loading: true,
          success: undefined,
        },
      };

    case AuthActionTypes.FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        forgotPassword: {
          loading: false,
          success: true,
        },
      };

    case AuthActionTypes.FORGOT_PASSWORD_FAILURE:
      return {
        ...state,
        forgotPassword: {
          loading: false,
          success: false,
        },
      };

    case AuthActionTypes.FORGOT_PASSWORD_RESET:
      return {
        ...state,
        forgotPassword: {
          loading: false,
          success: undefined,
        },
      };

    case AuthActionTypes.NEW_PASSWORD_REQUEST:
      return {
        ...state,
        newPassword: {
          loading: true,
          success: undefined,
        },
      };
    case AuthActionTypes.NEW_PASSWORD_SUCCESS:
      return {
        ...state,
        newPassword: {
          loading: false,
          success: true,
        },
      };
    case AuthActionTypes.NEW_PASSWORD_FAILURE:
      return {
        ...state,
        newPassword: {
          loading: false,
          success: false,
        },
      };
    case AuthActionTypes.NEW_PASSWORD_RESET:
      return {
        ...state,
        newPassword: {
          loading: false,
          success: undefined,
        },
      };

    default:
      return state;
  }
}

export default authReducer;
