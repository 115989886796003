import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from '@emotion/styled';

import { RootState } from '../../store';
import {
  createPaygTranslationPaymentRequest,
  createTranslationRequest,
} from '../../store/actions/translationActions';
import ChooseTargetLanguage from './ChooseTargetOptions';
import ChooseDocument from './ChooseDocument';
import ReviewQuote from './ReviewQuote';
import { LoadingSpinner, MobileStepper } from '../../components';
import PaymentInfo from './PaymentInfo';
import ChooseTargetOptions from './ChooseTargetOptions';
import { PaygTranslationQuote } from 'src/store/models/TranslationModels';
import { useNavbar } from 'src/context/NavbarContext';

const StyledPageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export interface Step {
  title: string;
  description: string;
  isShowDescription: boolean;
  component: React.ReactNode;
  isContentCenteredVertically: boolean;
  isContentCenteredHorizontally: boolean;
  goBackToQuoteStep?: () => void;
}

const CreateTranslationPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { navbarHeight } = useNavbar();

  const targetLanguages = useSelector(
    (state: RootState) => state.translations.targetLanguages
  );

  const { paygTranslationPayment } = useSelector(
    (state: RootState) => state.translations
  );

  const { translation } = useSelector((state: RootState) => state.translations);
  const { paygPricePlan } = useSelector(
    (state: RootState) => state.user.user!.organization
  );

  const [paygTranslationQuote, setPaygTranslationQuote] = useState<
    PaygTranslationQuote | undefined
  >(undefined);

  const { id: userId } = useSelector((state: RootState) => state.user.user!);

  const [file, setFile] = useState<File | null>(null);
  const [targetLanguageValue, setTargetLanguageValue] = useState<
    undefined | string
  >(undefined);

  // TODO OutputFormat should be an enum
  const [outputFormat, setOutputFormat] = useState<string>('same-as-original');

  useEffect(() => {
    console.log('translation', translation);
    console.log('paygPricePlan', paygPricePlan);
    if (translation && paygPricePlan) {
      const { pagePrice, currency } = paygPricePlan;

      const subTotal = pagePrice * translation.sourceDocument.pageCount;

      const paygTranslationQuotePayload: PaygTranslationQuote = {
        translationId: translation.id,
        filename: translation.sourceDocument.name,
        pageCount: translation.sourceDocument.pageCount,
        pagePrice,
        currency,
        subTotal,
      };

      console.log('setting paygTranslationQuote', paygTranslationQuotePayload);
      setPaygTranslationQuote(paygTranslationQuotePayload);
    }
  }, [translation, paygPricePlan]);

  useEffect(() => {
    if (paygTranslationPayment?.stripePayment.id) {
      searchParams.set(
        'paymentId',
        paygTranslationPayment.stripePayment.id.toString()
      );
    }
  }, [paygTranslationPayment]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (!file) return;

    const targetLanguageId = targetLanguages.find(
      (lang) => lang.value === targetLanguageValue
    )?.id;
    if (!targetLanguageId) return;

    const formData = new FormData();
    formData.append('user_id', userId!.toString());
    formData.append('target_language_id', targetLanguageId.toString());
    formData.append('file', file);
    if (outputFormat !== 'same-as-original') {
      formData.append('output_format', outputFormat);
    }

    dispatch(createTranslationRequest(formData));
  }, [file]);

  const [steps, setSteps] = useState<Step[]>([
    {
      title: t('Step 1: Target Language & Format'),
      description: '',
      isShowDescription: true,
      isContentCenteredVertically: true,
      isContentCenteredHorizontally: true,
      component: (
        <ChooseTargetOptions
          value={targetLanguageValue}
          setValue={setTargetLanguageValue}
          outputFormat={outputFormat}
          setOutputFormat={setOutputFormat}
          completeStep={() => {
            setStepsIsComplete((prevState) => [true, ...prevState.slice(1)]);
          }}
        />
      ),
    },
  ]);

  // Impportant this is the same length as steps belows
  const [stepsIsComplete, setStepsIsComplete] = useState<boolean[]>([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    const translationSteps: Step[] = [
      {
        title: t('Step 1: Target Language & Format'),
        description: '',
        isContentCenteredVertically: true,
        isContentCenteredHorizontally: true,
        isShowDescription: true,
        component: (
          <ChooseTargetLanguage
            value={targetLanguageValue}
            setValue={setTargetLanguageValue}
            outputFormat={outputFormat}
            setOutputFormat={setOutputFormat}
            completeStep={() => {
              setStepsIsComplete((prevState) => [true, ...prevState.slice(1)]);
            }}
          />
        ),
      },
      {
        title: t('Step 2: Choose Document'),
        description: t('.pdf files are supported'),
        isShowDescription: false,
        isContentCenteredVertically: true,
        isContentCenteredHorizontally: true,
        component: (
          <ChooseDocument
            file={file}
            setFile={setFile}
            setActiveStep={setActiveStep}
            completeStep={() => {
              setStepsIsComplete((prevState) =>
                prevState.map((item, index) => (index === 1 ? true : item))
              );
            }}
          />
        ),
      },
      {
        title: t('Step 3: Review Quote'),
        description: t(
          "Based on the length of your document and the pricing tier you chose, we'll let you know how much your translation will cost"
        ),
        isShowDescription: false,
        isContentCenteredVertically: true,
        isContentCenteredHorizontally: true,
        component: paygTranslationQuote ? (
          <ReviewQuote
            paygTranslationQuote={paygTranslationQuote}
            completeStep={() => {
              if (!translation) {
                // TODO throw error or show toast
                return;
              }
              dispatch(createPaygTranslationPaymentRequest(translation.id));
              setStepsIsComplete((prevState) =>
                prevState.map((item, index) => (index === 2 ? true : item))
              );
              setActiveStep((prevState) => prevState + 1);
              searchParams.set('isPolling', 'false');
            }}
            setActiveStep={setActiveStep}
            resetState={() => {
              setStepsIsComplete((prevState) => prevState.map(() => false));
              setFile(null);
              setTargetLanguageValue(undefined);
            }}
          />
        ) : (
          <LoadingSpinner />
        ),
      },
      {
        title: t('Step 4: Payment'),
        description: t('Enter your credit card information'),
        isShowDescription: false,
        isContentCenteredVertically: true,
        isContentCenteredHorizontally: true,
        component: paygTranslationQuote ? (
          <PaymentInfo
            paygTranslationQuote={paygTranslationQuote}
            goBackToQuoteStep={() => {
              setActiveStep((prevState) => prevState - 1);
            }}
            completeStep={() => navigate('/create-translation/success')}
          />
        ) : (
          <LoadingSpinner />
        ),
      },
    ];

    setSteps(translationSteps);
  }, [
    targetLanguageValue,
    file,
    dispatch,
    navigate,
    t,
    paygTranslationPayment,
    searchParams,
    outputFormat,
    paygTranslationQuote,
    translation,
    paygPricePlan,
  ]);

  return (
    <StyledPageContainer>
      <MobileStepper
        navbarHeight={navbarHeight}
        steps={steps}
        setSteps={setSteps}
        activeStep={activeStep}
        setActiveStep={setActiveStep}
        setTargetLanguageValue={setTargetLanguageValue}
        stepsIsComplete={stepsIsComplete}
        setStepsIsComplete={setStepsIsComplete}
      />
    </StyledPageContainer>
  );
};

export default CreateTranslationPage;
