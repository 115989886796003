import { useEffect } from 'react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import {
  errorColor,
  FlexRow,
  primaryColor1,
  AuthPageContainer,
} from 'src/styles/constants';
import ResetPasswordForm from 'src/forms/enterprise/ResetPasswordForm';
import { LoadingSpinner } from 'src/components';
import { RootState } from 'src/store';
import { resetNewPasswordState } from 'src/store/actions/userActions';

const Success = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page after 3 seconds on success
    const timer = setTimeout(() => {
      navigate('/enterprise/login');
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <FlexRow>
      <Typography variant='h6' style={{ color: `${primaryColor1}` }}>
        {t('Password successfully reset! Redirecting to login...')}
      </Typography>
    </FlexRow>
  );
};

const Failure = () => {
  const { t } = useTranslation();
  return (
    <FlexRow>
      <Typography variant='h6' style={{ color: `${errorColor}` }}>
        {t(
          'Error: Failed to reset password. Please try again or contact support.'
        )}
      </Typography>
    </FlexRow>
  );
};

const ResetPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { loading, success } = useSelector(
    (state: RootState) => state.user.newPassword
  );

  // Get email and token from URL params
  const email = searchParams.get('email');
  const token = searchParams.get('token');

  useEffect(() => {
    // Cleanup on component unmount
    return () => {
      dispatch(resetNewPasswordState());
    };
  }, [dispatch]);

  // Validate required params
  if (!email || !token) {
    return (
      <AuthPageContainer>
        <Typography variant='h6' style={{ color: `${errorColor}` }}>
          Invalid password reset link. Please request a new one.
        </Typography>
      </AuthPageContainer>
    );
  }

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    } else if (success) {
      return <Success />;
    } else if (success === false) {
      return <Failure />;
    }
    return <ResetPasswordForm email={email} token={token} loading={loading} />;
  };

  return <AuthPageContainer>{renderContent()}</AuthPageContainer>;
};

export default ResetPasswordPage;
