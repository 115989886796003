import { Divider, Typography } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import WorkIcon from '@mui/icons-material/Work';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { errorColor, FlexRow, primaryColor1 } from 'src/styles/constants';
import SignInOption from './SignInOption';
import { useNavigate } from 'react-router-dom';
import useDimensions from 'src/hooks/useDimensions';
import { RootState } from 'src/store';

const StyledPageContainer = styled.div`
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const SignInPage = ({
  handleGoogleLogin,
}: {
  handleGoogleLogin: () => void;
}) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { authError } = useSelector((state: RootState) => state.user);

  const [buttonRef, buttonDimensions] = useDimensions();

  return (
    <StyledPageContainer>
      <br />
      <FlexRow>
        <SignInOption
          ref={buttonRef}
          title={t('Public')}
          buttonName={t('SIGN IN WITH GOOGLE')}
          buttonNameShort={t('SIGN IN')}
          callBack={handleGoogleLogin}
          icon={<GoogleIcon />}
        />{' '}
        <Divider orientation='vertical'>{t('OR')}</Divider>{' '}
        <SignInOption
          icon={<WorkIcon />}
          title={t('Enterprise')}
          minButtonWidth={buttonDimensions.width}
          buttonName={t('ENTERPRISE SIGN IN')}
          buttonNameShort={t('SIGN IN')}
          callBack={() => navigate('/enterprise/login')}
        />
      </FlexRow>
      <FlexRow>
        {authError && (
          <p style={{ color: `${errorColor}` }}>
            {t('Error')}: {t(authError)}
          </p>
        )}
      </FlexRow>
    </StyledPageContainer>
  );
};

export default SignInPage;
