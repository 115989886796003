import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import styled from '@emotion/styled';

import {
  backgroundColor2,
  primaryColor2Darkened,
} from '../../styles/constants';
import { PaygTranslationQuote } from '../../store/models/TranslationModels';

const StyledTable = styled.table`
  width: 100%;
  padding: 16px;
  background-color: ${backgroundColor2};
  border-collapse: collapse;

  th,
  td {
    padding: 8px;
  }

  th {
    font-weight: normal;
    color: #666;
    text-align: left;
  }

  td {
    font-weight: bold;
    text-align: right;
  }

  .totalCost {
    font-weight: bold;
    font-size: 1.2em;
    color: #333;
  }
`;

interface QuoteCardProps {
  paygTranslationQuote: PaygTranslationQuote;
}

const PaygQuoteCard = ({ paygTranslationQuote }: QuoteCardProps) => {
  const { filename, pageCount, pagePrice, subTotal, currency } =
    paygTranslationQuote;
  const { t } = useTranslation();

  return (
    <Card variant='outlined' sx={{ border: 'none' }}>
      <StyledTable>
        <tbody>
          <tr>
            <th>{t('Filename')}:</th>
            <td>{filename}</td>
          </tr>
          <tr></tr>
          <tr>
            <th>{t('Price Per Page')}:</th>
            <td>{pagePrice.toFixed(2)}</td>
          </tr>
          <tr
            style={{
              borderBottom: '1px solid',
            }}
          >
            <th>
              <p
                style={{
                  display: 'inline',
                  color: primaryColor2Darkened,
                }}
              >
                {t('Page Count')}:
              </p>
            </th>
            <td>{pageCount}</td>
          </tr>

          <tr className='totalCost'>
            <th>
              {t('Cost')} ({currency.value}):
            </th>
            <td>
              <span>{subTotal.toFixed(2)}</span>
            </td>
          </tr>
        </tbody>
      </StyledTable>
    </Card>
  );
};

export default PaygQuoteCard;
