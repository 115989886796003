import React, { createContext, useContext, useState } from 'react';

interface NavbarContextType {
  navbarHeight: number;
  setNavbarHeight: (height: number) => void;
}

const NavbarContext = createContext<NavbarContextType | undefined>(undefined);

export const NavbarProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [navbarHeight, setNavbarHeight] = useState(0);

  return (
    <NavbarContext.Provider value={{ navbarHeight, setNavbarHeight }}>
      {children}
    </NavbarContext.Provider>
  );
};

export const useNavbar = () => {
  const context = useContext(NavbarContext);
  if (context === undefined) {
    throw new Error('useNavbar must be used within a NavbarProvider');
  }
  return context;
};
