import React, { useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { Typography } from '@mui/material';
import styled from '@emotion/styled';

import {
  ContactUsPage,
  GoogleOAuth2RedirectPage,
  JoinWaitlistPage,
  LandingPage,
  SuccessfulTranslationPage,
  FaqPage,
  EnterpriseLoginPage,
  EnterpriseRegisterPage,
  SignInPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  AccountPage,
  HomePage,
} from './pages';
import { LoadingSpinner, Navbar } from './components';
import { backgroundColor2, primaryColor2 } from './styles/constants';
import CreateTranslationPage from './pages/CreateTranslationPage/CreateTranslationPage';
import { useForceAgreements } from './hooks';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import TermsOfServicePage from './pages/TermsOfServicePage';
import { fetchCountriesRequest } from './store/actions/miscActions';
import { fetchTargetLanguages } from './store/actions/translationActions';
import './index.css';
import { NavbarProvider, useNavbar } from './context/NavbarContext';

const GOOGLE_CLIENT_ID =
  '742130881631-usju32s8hfig34n29gvip62sqqr5pq8c.apps.googleusercontent.com';

let baseUrl: string | undefined;
if (process.env.NODE_ENV === 'development') {
  baseUrl = 'http://localhost:3000';
} else {
  // TODO figure out which, won't work for staging for now
  baseUrl = 'https://trash-bandit-translations.com';
}

const REDIRECT_URI = `${baseUrl}/oauth2callback`;

const handleGoogleLogin = () => {
  const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?response_type=code&client_id=${encodeURIComponent(
    GOOGLE_CLIENT_ID
  )}&redirect_uri=${encodeURIComponent(REDIRECT_URI)}&scope=email`;

  // Redirect the user to the Google Auth URL
  window.location.href = googleAuthUrl;
};

const StyledAppBackground = styled.div`
  background-color: ${backgroundColor2};
  min-height: 100vh;
  overflow: hidden; // Add this to prevent scrolling
  position: relative; // Add this for proper stacking
`;

const ComingSoon = () => (
  <Typography variant='h4' color={primaryColor2}>
    Coming soon
  </Typography>
);

const Logout = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'LOGOUT' });
  }, [dispatch]);

  return <LoadingSpinner size='xl' />;
};

const AppContent = () => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const { setNavbarHeight } = useNavbar();
  const dispatch = useDispatch();

  useForceAgreements();

  useEffect(() => {
    if (navbarRef.current) {
      setNavbarHeight(navbarRef.current.clientHeight);
    }
  }, [setNavbarHeight]);

  useEffect(() => {
    dispatch(fetchCountriesRequest());
    dispatch(fetchTargetLanguages());
  }, [dispatch]);

  return (
    <StyledAppBackground>
      <Navbar ref={navbarRef} handleGoogleLogin={handleGoogleLogin} />
      <Routes>
        <Route
          path='/'
          element={<LandingPage handleGoogleLogin={handleGoogleLogin} />}
        />
        <Route path='/oauth2callback' element={<GoogleOAuth2RedirectPage />} />
        <Route path='/create-translation' element={<CreateTranslationPage />} />
        <Route
          path='/create-translation/success'
          element={<SuccessfulTranslationPage />}
        />

        <Route
          path='/join-waitlist/product/:productId/:productLabel'
          element={<JoinWaitlistPage />}
        />
        <Route path='/privacy-policy' element={<PrivacyPolicyPage />} />
        <Route path='/terms-of-service' element={<TermsOfServicePage />} />

        <Route path='/faq' element={<FaqPage />} />
        <Route path='/help' element={<ComingSoon />} />
        <Route path='/contact-us' element={<ContactUsPage />} />

        <Route
          path='/sign-in'
          element={<SignInPage handleGoogleLogin={handleGoogleLogin} />}
        />

        <Route path='/logout' element={<Logout />} />

        <Route path='/enterprise/login' element={<EnterpriseLoginPage />} />
        <Route
          path='/enterprise/register'
          element={<EnterpriseRegisterPage />}
        />

        <Route path='/forgot-password' element={<ForgotPasswordPage />} />
        <Route path='/forgot-password-reset' element={<ResetPasswordPage />} />
        <Route path='/account' element={<AccountPage />} />
        <Route path='/home' element={<HomePage />} />
      </Routes>
    </StyledAppBackground>
  );
};

const App = () => {
  return (
    <NavbarProvider>
      <AppContent />
    </NavbarProvider>
  );
};

export default App;
