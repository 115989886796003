import api from '../../utils/api';

export const fetchTargetLanguages = async () => {
  return await api.get('/translations/target-languages');
};

export const fetchTranslations = async () => {
  return await api.get('/translations');
};

export const createTranslation = async (formData: FormData) => {
  return await api.post('/translations', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const createPaygTranslationPayment = async (translationId: number) => {
  return await api.post('/translations/payg_payment', { translationId });
};

export const updateTranslationPayment = async (
  paymentId: number,
  paymentStatus: string
) => {
  return await api.put(
    `/translations/payment/${paymentId}/status/${paymentStatus}`
  );
};

export const createOrUpdateDeliveryEmail = async (email: string) => {
  return await api.post('/translations/delivery-email', { email });
};

export const deletePaygTranslationStripePayment = async (
  translationId: number,
  stripePaymentId: number
) => {
  return await api.delete(
    `/translations/${translationId}/payg-stripe-payment/${stripePaymentId}`
  );
};
