import { useFormik } from 'formik';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { TextField, MenuItem, Grid, Button, Typography } from '@mui/material';
import styled from '@emotion/styled';

import {
  errorColor,
  errorColorLightened,
  primaryColor2,
  primaryColor2Darkened,
} from '../styles/constants';
import { RootState } from '../store';
import { LoadingSpinner } from '../components';
import { updateBillingAddress } from '../store/actions/userActions';
import {
  BillingAddress,
  UpdateBillingAddressPayload,
} from '../store/models/UserModels';
import { Region } from '../store/models/MiscModels';

const StyledTextField = styled(TextField)`
  margin: 0.5rem 0;

  .MuiOutlinedInput-notchedOutline {
    border-radius: 0;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`;

const SaveButton = styled(Button)({
  backgroundColor: primaryColor2,
  color: 'white',
  '&:hover': {
    backgroundColor: primaryColor2Darkened,
  },
});

const CancelButton = styled(Button)({
  backgroundColor: errorColor,
  color: 'white',
  '&:hover': {
    backgroundColor: errorColorLightened,
  },
});

const validationSchema = yup.object({
  street: yup.string().required('Street Address is required'),
  city: yup.string().required('City is required'),
  province: yup.string().required('Required field'),
  postalCode: yup.string().required('Required field'),
  country: yup.string().required('Country is required'),
});

interface AddressFormProps {
  setIsEditingBillingAddress: (isEditingBillingAddress: boolean) => void;
  t: any;
  goBackToQuoteStep: () => void;
  translationQuoteBillingAddress: BillingAddress | undefined;
}

const AddressForm = ({
  setIsEditingBillingAddress,
  t,
  goBackToQuoteStep,
  translationQuoteBillingAddress,
}: AddressFormProps) => {
  const dispatch = useDispatch();
  const {
    updateBillingAddress: { loading },
    user,
  } = useSelector((state: RootState) => state.user);
  const { countries } = useSelector((state: RootState) => state.misc);

  const formik = useFormik({
    initialValues: {
      street: '',
      city: '',
      province: '',
      postalCode: '',
      country: countries[0].value,
    },
    validationSchema,
    onSubmit: async (values) => {
      const payload: UpdateBillingAddressPayload = {
        userId: user?.id!,
        street: values.street,
        city: values.city,
        region: values.province,
        postalCode: values.postalCode,
      };

      console.log('Formik errors:', formik.errors); // Log Formik errors

      dispatch(updateBillingAddress(payload, setIsEditingBillingAddress));
    },
  });

  const handlePostalCodeChange = (event: any) => {
    const { value } = event.target;
    const formattedValue = value.toUpperCase().slice(0, 7); // Capitalize and limit to 6 characters
    formik.setFieldValue('postalCode', formattedValue);
  };

  const handlePostalCodeBlur = (event: any) => {
    const formattedValue = event.target.value.replace(/(.{3})(.+)/, '$1 $2');
    formik.setFieldValue('postalCode', formattedValue, true);
    formik.setFieldTouched('postalCode', true, true);
  };

  return loading ? (
    <LoadingSpinner />
  ) : (
    <form style={{ padding: '0.25rem' }} onSubmit={formik.handleSubmit}>
      <br />
      <br />
      <Typography align='center' variant='h5'>
        {t('Billing Address')}
      </Typography>
      <br />
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            id='street'
            name='street'
            label={t('Street Address')}
            value={formik.values.street}
            onChange={formik.handleChange}
            error={formik.touched.street && Boolean(formik.errors.street)}
            helperText={formik.touched.street && formik.errors.street}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            id='city'
            name='city'
            label={t('City')}
            value={formik.values.city}
            onChange={formik.handleChange}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            id='province'
            name='province'
            label={formik.values.country === 'US' ? t('State') : t('Province')}
            select
            value={formik.values.province}
            onChange={formik.handleChange}
            error={formik.touched.province && Boolean(formik.errors.province)}
            helperText={formik.touched.province && formik.errors.province}
            disabled={!formik.values.country}
          >
            {countries
              .filter((c) => c.value === formik.values.country)[0]
              .regions.map((option: Region) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.value}
                </MenuItem>
              ))}
          </StyledTextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            id='country'
            name='country'
            label={t('Country')}
            select
            onChange={formik.handleChange}
            value={formik.values.country}
            error={formik.touched.country && Boolean(formik.errors.country)}
          >
            {countries.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </StyledTextField>
        </Grid>
        <Grid item xs={12} md={6}>
          <StyledTextField
            fullWidth
            id='postalCode'
            name='postalCode'
            label={
              formik.values.country === 'US' ? 'Zip Code' : t('Postal Code')
            }
            value={formik.values.postalCode}
            onChange={handlePostalCodeChange}
            onBlur={handlePostalCodeBlur}
            error={
              formik.touched.postalCode && Boolean(formik.errors.postalCode)
            }
            helperText={formik.touched.postalCode && formik.errors.postalCode}
          />
        </Grid>
      </Grid>
      <br />
      <br />
      <CancelButton
        disabled={loading}
        onClick={() => {
          if (translationQuoteBillingAddress) {
            setIsEditingBillingAddress(false);
          } else {
            goBackToQuoteStep();
          }
        }}
      >
        {t('Cancel')}
      </CancelButton>
      <SaveButton
        disabled={loading}
        style={{ marginLeft: '0.5rem' }}
        type='submit'
      >
        {t('SAVE')}
      </SaveButton>
    </form>
  );
};

export default AddressForm;
