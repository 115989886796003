import { Typography } from '@mui/material';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as Yup from 'yup';
import styled from '@emotion/styled';
import TextField from '@mui/material/TextField';

import { FlexRow, AcceptButton, primaryColor1 } from 'src/styles/constants';
import { newPasswordRequest } from 'src/store/actions/userActions';

const StyledTextField = styled(TextField)`
  margin: 0.5rem 0;
`;

interface ResetPasswordFormProps {
  email: string;
  token: string;
  loading: boolean;
}

const ResetPasswordForm = ({
  email,
  token,
  loading,
}: ResetPasswordFormProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(5, t('Password must be at least 5 characters'))
        .required(t('Password is required')),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password')], t('Passwords must match'))
        .required(t('Please confirm your password')),
    }),
    onSubmit: (values) => {
      dispatch(newPasswordRequest(email, token, values.password));
    },
  });

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <FlexRow>
          <Typography
            variant='h4'
            sx={{ color: primaryColor1, marginBottom: '2rem' }}
          >
            {t('Reset Your Password')}
          </Typography>
        </FlexRow>

        <StyledTextField
          fullWidth
          id='email'
          name='email'
          label={t('Email')}
          value={email}
          InputProps={{ readOnly: true }}
          InputLabelProps={{ shrink: true }}
        />

        <StyledTextField
          fullWidth
          id='password'
          name='password'
          label={t('New Password')}
          type='password'
          value={formik.values.password}
          onChange={formik.handleChange}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
          InputLabelProps={{ shrink: true }}
          autoComplete='new-password'
        />

        <StyledTextField
          fullWidth
          id='confirmPassword'
          name='confirmPassword'
          label={t('Confirm New Password')}
          type='password'
          value={formik.values.confirmPassword}
          onChange={formik.handleChange}
          error={
            formik.touched.confirmPassword &&
            Boolean(formik.errors.confirmPassword)
          }
          helperText={
            formik.touched.confirmPassword && formik.errors.confirmPassword
          }
          InputLabelProps={{ shrink: true }}
          autoComplete='new-password'
        />

        <AcceptButton
          disabled={loading}
          variant='contained'
          fullWidth
          type='submit'
          data-testid='reset-password-submit-button'
        >
          {loading ? t('Resetting...') : t('Reset Password')}
        </AcceptButton>
      </form>
    </div>
  );
};

export default ResetPasswordForm;
