import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import {
  errorColor,
  FlexRow,
  primaryColor1,
  AuthPageContainer,
} from 'src/styles/constants';
import ForgotPasswordForm from 'src/forms/enterprise/ForgotPasswordForm';
import { LoadingSpinner } from 'src/components';
import { RootState } from 'src/store';
import { useSelector } from 'react-redux';

const Success = () => {
  const { t } = useTranslation();
  return (
    <FlexRow>
      <Typography variant='h6' style={{ color: `${primaryColor1}` }}>
        {t('Success! Check your email for a password reset link.')}
      </Typography>
    </FlexRow>
  );
};

const Failure = () => {
  const { t } = useTranslation();
  return (
    <FlexRow>
      <Typography variant='h6' style={{ color: `${errorColor}` }}>
        {t('Error: Something went wrong. Contact support.')}
      </Typography>
    </FlexRow>
  );
};

const ForgotPasswordPage = () => {
  const { loading, success } = useSelector(
    (state: RootState) => state.user.forgotPassword
  );

  const renderContent = () => {
    if (loading) {
      return <LoadingSpinner />;
    } else if (success) {
      return <Success />;
    } else if (success === false) {
      return <Failure />;
    }
    return <ForgotPasswordForm loading={loading} />;
  };

  return <AuthPageContainer>{renderContent()}</AuthPageContainer>;
};

export default ForgotPasswordPage;
