import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import RadioButtonGroup from '../../components/RadioButtonGroup';
import { LoadingSpinner } from '../../components';
import { t } from 'i18next';
import styled from '@emotion/styled';

interface ChooseTargetOptionsProps {
  value: string | undefined;
  setValue: (value: string) => void;
  outputFormat: string | undefined;
  setOutputFormat: (value: string) => void;
  completeStep: () => void;
}

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const targetFormats = [
  { label: 'Same as original', value: 'same-as-original' },
  { label: 'docx', value: 'docx' },
  { label: 'pdf', value: 'pdf' },
];

const ChooseTargetOptions = ({
  value,
  setValue,
  outputFormat,
  setOutputFormat,
  completeStep,
}: ChooseTargetOptionsProps) => {
  const { loading, targetLanguages } = useSelector(
    (state: RootState) => state.translations
  );

  return loading ? (
    <LoadingSpinner size='l' />
  ) : (
    <StyledContainer>
      <RadioButtonGroup
        title={t('Target Language')}
        options={targetLanguages}
        value={value}
        setValue={setValue}
        completeStep={completeStep}
      />

      <br />
      <br />

      <RadioButtonGroup
        title={t('Target Format')}
        options={targetFormats}
        value={outputFormat}
        setValue={setOutputFormat}
        completeStep={completeStep}
      />
    </StyledContainer>
  );
};

export default ChooseTargetOptions;
