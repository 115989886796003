import { call, put, select, take, takeLatest } from 'redux-saga/effects';
import {
  loginWithGoogleSuccess,
  AuthAction,
  loginWithGoogleFailure,
  acceptPrivacyPolicySuccess,
  acceptPrivacyPolicyFailure,
  acceptTermsOfServiceSuccess,
  acceptTermsOfServiceFailure,
  fetchUserSuccess,
  updateBillingAddressSuccess,
} from '../actions/userActions';
import {
  AuthActionTypes,
  fetchUser as fetchUserAction,
} from '../actions/userActions';
import {
  acceptPrivacyPolicy,
  acceptTermsOfService,
  contactUs,
  getAuthTokenCheck,
  loginWithGoogle,
  updateUserLanguage,
  fetchUser,
  registerEnterpriseUser,
  loginEnterpriseUser,
  updateBillingAddress,
  forgotPassord,
  resetPassword,
} from '../api/userApi';
import { callGenerator } from './sagaUtils';
import { RootState } from '..';
import {
  createPaygTranslationPaymentRequest,
  deletePaygTranslationPaymentRequest,
  TranslationActionTypes,
} from '../actions/translationActions';

function* fetchUserSaga() {
  try {
    const {
      data: { user, token },
    } = yield* callGenerator(fetchUser);
    yield put(fetchUserSuccess({ user, token }));
  } catch (error: any) {
    yield put({ type: AuthActionTypes.FETCH_USER_FAILURE });
  }
}

function* loginUserWithGoogleSaga(action: AuthAction) {
  try {
    const {
      data: { user, token },
    } = yield* callGenerator(loginWithGoogle, action.payload);
    yield put(loginWithGoogleSuccess({ user, token }));
  } catch (error: any) {
    console.log('ERROR', error);
    const errorMsg = error.response.data.detail;
    yield put(loginWithGoogleFailure(errorMsg));

    console.log('ERROR', error);
  } finally {
    yield put({ type: AuthActionTypes.RESET_LOGIN_LOADING });
  }
}

function* checkAuthTokenSaga(action: AuthAction) {
  try {
    yield* callGenerator(getAuthTokenCheck);
    // If it's still good do nothing
  } catch (error: any) {
    // If it's not good, log out
    yield put({ type: AuthActionTypes.LOGOUT });
  }
}

function* acceptPrivacyPolicySaga(action: AuthAction) {
  try {
    const {
      data: { user },
    } = yield* callGenerator(acceptPrivacyPolicy);
    yield put(acceptPrivacyPolicySuccess(user));
  } catch (error: any) {
    yield put(acceptPrivacyPolicyFailure(error.message));
    console.log('ERROR', error);
  } finally {
    yield put({ type: AuthActionTypes.RESET_LOGIN_LOADING });
  }
}

function* accepTermsOfServiceSaga(action: AuthAction) {
  try {
    const {
      data: { user },
    } = yield* callGenerator(acceptTermsOfService);
    yield put(acceptTermsOfServiceSuccess(user));
  } catch (error: any) {
    yield put(acceptTermsOfServiceFailure(error.message));
    console.log('ERROR', error);
  } finally {
    yield put({ type: AuthActionTypes.RESET_LOGIN_LOADING });
  }
}

function* contactUsSaga(action: AuthAction) {
  const token: string = yield select((state: RootState) => state.user.token);
  const { message, email } = action.payload;

  try {
    yield* callGenerator(contactUs, token, message, email);
    yield put({ type: AuthActionTypes.CONTACT_US_SUCCESS });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.CONTACT_US_FAILURE });
  }
}

function* updateUserLanguageSaga(action: AuthAction) {
  const token: string = yield select((state: RootState) => state.user.token);
  const language = action.payload;

  try {
    const { data } = yield* callGenerator(updateUserLanguage, token, language);
    yield put({
      type: AuthActionTypes.UPDATE_USER_LANGUAGE_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.UPDATE_USER_LANGUAGE_FAILURE });
  }
}

function* registerEnterpriseUserSaga(action: AuthAction) {
  try {
    const { data } = yield* callGenerator(
      registerEnterpriseUser,
      action.payload
    );
    yield put({
      type: AuthActionTypes.ENTERPRISE_REGISTER_USER_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.ENTERPRISE_REGISTER_USER_FAILURE });
  } finally {
    yield put({ type: AuthActionTypes.RESET_REGISTER_LOADING });
  }
}

function* loginEnterpriseUserSaga(action: AuthAction) {
  try {
    const { data } = yield* callGenerator(loginEnterpriseUser, action.payload);
    yield put({
      type: AuthActionTypes.ENTERPRISE_LOGIN_USER_SUCCESS,
      payload: data,
    });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.ENTERPRISE_LOGIN_USER_FAILURE });
  } finally {
    yield put({ type: AuthActionTypes.RESET_LOGIN_LOADING });
  }
}

function* updateBillingAddressSaga(
  action: AuthAction
): Generator<any, void, any> {
  const translationId: number = yield select(
    (state: RootState) => state.translations.translation?.id
  );
  const stripePaymentId: number = yield select(
    (state: RootState) =>
      state.translations.paygTranslationPayment?.stripePayment.id
  );

  try {
    yield* callGenerator(updateBillingAddress, action.payload);
    yield put(updateBillingAddressSuccess());

    if (translationId && stripePaymentId) {
      // Below is necessary do ensure we create a new payment after the delete
      const deleteAction = deletePaygTranslationPaymentRequest(
        translationId,
        stripePaymentId
      );
      yield put(deleteAction);

      // Wait for delete saga to complete
      const deleteResult: boolean = yield call(function* () {
        const result: { type: string } = yield take([
          TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_SUCCESS,
          TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_FAILURE,
        ]);
        return (
          result.type ===
          TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_SUCCESS
        );
      });

      // Only create new payment if delete was successful
      if (deleteResult) {
        yield put(createPaygTranslationPaymentRequest(translationId));
      }
    }

    yield put(fetchUserAction());

    action.payload.callback(false);
  } catch (error: any) {
    console.log('ERROR', error);
  }
}

function* forgotPasswordSaga(action: AuthAction) {
  try {
    yield* callGenerator(forgotPassord, action.payload.email);
    yield put({ type: AuthActionTypes.FORGOT_PASSWORD_SUCCESS });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.FORGOT_PASSWORD_FAILURE });
  }
}

function* newPasswordSaga(action: AuthAction) {
  const { email, token, password } = action.payload;
  try {
    yield* callGenerator(resetPassword, email, token, password);
    yield put({ type: AuthActionTypes.NEW_PASSWORD_SUCCESS });
  } catch (error: any) {
    yield put({ type: AuthActionTypes.NEW_PASSWORD_FAILURE });
  }
}

export default function* rootSaga() {
  yield takeLatest(AuthActionTypes.FETCH_USER_REQUEST, fetchUserSaga);

  yield takeLatest(
    AuthActionTypes.LOGIN_WITH_GOOGLE_REQUEST,
    loginUserWithGoogleSaga
  );

  yield takeLatest(
    AuthActionTypes.ACCEPT_PRIVACY_POLICY_REQUEST,
    acceptPrivacyPolicySaga
  );

  yield takeLatest(
    AuthActionTypes.ACCEPT_TERMS_OF_SERVICE_REQUEST,
    accepTermsOfServiceSaga
  );

  yield takeLatest(AuthActionTypes.CONTACT_US_REQUEST, contactUsSaga);

  yield takeLatest(
    AuthActionTypes.UPDATE_USER_LANGUAGE_REQUEST,
    updateUserLanguageSaga
  );

  yield takeLatest(AuthActionTypes.CHECK_AUTH_TOKEN, checkAuthTokenSaga);

  yield takeLatest(
    AuthActionTypes.ENTERPRISE_REGISTER_USER_REQUEST,
    registerEnterpriseUserSaga
  );

  yield takeLatest(
    AuthActionTypes.ENTERPRISE_LOGIN_USER_REQUEST,
    loginEnterpriseUserSaga
  );

  yield takeLatest(
    AuthActionTypes.UPDATE_BILLING_ADDRESS_REQUEST,
    updateBillingAddressSaga
  );

  yield takeLatest(AuthActionTypes.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);

  yield takeLatest(AuthActionTypes.NEW_PASSWORD_REQUEST, newPasswordSaga);
}
