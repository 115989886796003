import { useTranslation } from 'react-i18next';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import styled from '@emotion/styled';
import Typography from '@mui/material/Typography';

interface RadioButtonGroupProps {
  title?: string;
  options: { label: string; value: string }[];
  value: string | undefined;
  setValue: (value: string) => void;
  completeStep: () => void;
}

const StyledRadioButtonGroupContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const RadioButtonGroup = ({
  title,
  options,
  value,
  setValue,
  completeStep,
}: RadioButtonGroupProps) => {
  const { t } = useTranslation();
  return (
    <StyledRadioButtonGroupContainer>
      {title && (
        <Typography sx={{ textDecoration: 'underline' }} variant='h6'>
          {title}
        </Typography>
      )}
      <FormControl
        id='form-control'
        component='fieldset'
        sx={{ width: 'fit-content' }}
      >
        <RadioGroup
          value={value}
          onChange={(event) => {
            setValue(event.target.value);
            completeStep();
          }}
        >
          {options.map((option, index) => (
            <FormControlLabel
              key={index}
              value={option.value}
              control={<Radio />}
              label={t(option.label)}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </StyledRadioButtonGroupContainer>
  );
};

export default RadioButtonGroup;
