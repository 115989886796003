import AddIcon from '@mui/icons-material/Add';
import { Fab } from '@mui/material';
import styled from '@emotion/styled';

import { primaryColor2 } from 'src/styles/constants';

export const StyledAddIcon = styled(AddIcon)`
  font-size: 2.5rem; // Adjust the size as per your preference
  font-weight: bold; // Make the icon thicker
`;

const ButtonContainer = styled(Fab)`
  position: sticky;
  bottom: 1rem;
  align-self: flex-start;
  align-items: center;
  background-color: ${primaryColor2};
  color: white;
  box-shadow: none;
  z-index: 100;
`;

interface AddButtonProps {
  onClick: () => void;
}

const AddButton: React.FC<AddButtonProps> = ({ onClick }) => (
  <ButtonContainer onClick={onClick}>
    <StyledAddIcon />
  </ButtonContainer>
);

export default AddButton;
