import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { primaryColor1 } from '../styles/constants';

const sizes = {
  s: 40,
  m: 60,
  l: 80,
  xl: 120,
  xxl: 160,
};

interface LoadingSpinnerProps {
  size?: keyof typeof sizes;
  addStyles?: object;
}

const LoadingSpinner = ({ size, addStyles }: LoadingSpinnerProps) => {
  return (
    <Box sx={{ display: 'flex' }}>
      <CircularProgress
        size={size ? sizes[size] : sizes['m']}
        sx={{ color: primaryColor1, ...addStyles }}
      />
    </Box>
  );
};

export default LoadingSpinner;
