import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from '@mui/material';

import { useDimensions } from 'src/hooks';
import { useNavbar } from '../../context/NavbarContext';
import { RootState } from 'src/store';
import { LoadingSpinner, AddButton } from 'src/components';
import { Translation } from 'src/store/models/TranslationModels';
import {
  cancelledColor,
  errorColor,
  FlexRow,
  PageContainer,
  PageContent,
  primaryColor1,
  primaryColor1Darkened,
  successColor,
} from 'src/styles/constants';
import { useEffect, useCallback, useState } from 'react';
import { fetchTranslationsRequest } from 'src/store/actions/translationActions';

const getStatusColor = (status: string) => {
  switch (status) {
    case 'completed':
      return successColor;
    case 'in-progress':
      return primaryColor1;
    case 'failed':
      return errorColor;
    case 'cancelled':
      return cancelledColor;
    default:
      return primaryColor1Darkened;
  }
};

const getStatusLabel = (statusValue: string) => {
  switch (statusValue) {
    case 'completed':
      return t('Completed');
    case 'in-progress':
      return t('In Progress');
    case 'failed':
      return t('Failed');
    case 'cancelled':
      return t('Cancelled');
    case 'created':
      return t('In queue');
    default:
      return t('Unknown');
  }
};

const EmptyState = () => (
  <TableRow>
    <TableCell colSpan={3} align='center' sx={{ py: 4 }}>
      <Typography variant='body1'>
        {t('Use the green button to create a translation')}
      </Typography>
    </TableCell>
  </TableRow>
);

const TranslationsList = ({
  translations,
  containerHeight,
  loading,
}: {
  translations: Translation[];
  containerHeight: string;
  loading: boolean;
}) => {
  const navigate = useNavigate();

  return (
    <div style={{ position: 'relative', height: containerHeight }}>
      <TableContainer
        component={Paper}
        sx={{
          boxShadow: 'none',
          height: '100%', // Take full height of parent
          overflow: 'auto',
          position: 'relative',
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('Document')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('Status')}</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{t('Date')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align='center' sx={{ py: 4 }}>
                  <FlexRow>
                    <LoadingSpinner size='xxl' />
                  </FlexRow>
                </TableCell>
              </TableRow>
            ) : translations.length === 0 ? (
              <EmptyState />
            ) : (
              translations.map((translation) => (
                <TableRow
                  key={translation.id}
                  sx={{ '&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' } }}
                >
                  <TableCell>{translation.sourceDocument.name}</TableCell>
                  <TableCell>
                    <p
                      style={{
                        color: getStatusColor(translation.status.value),
                      }}
                    >
                      {t(getStatusLabel(translation.status.value))}
                    </p>
                  </TableCell>
                  <TableCell>
                    {translation.date
                      ? new Date(translation.date).toLocaleDateString()
                      : '-'}
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div
        style={{
          position: 'absolute',
          bottom: '2rem',
          right: '2rem',
          zIndex: 100,
        }}
      >
        <AddButton onClick={() => navigate('/create-translation')} />
      </div>
    </div>
  );
};

const HomePage = () => {
  const dispatch = useDispatch();
  const [isInitialLoad, setIsInitialLoad] = useState(true); // Add this back

  const { navbarHeight } = useNavbar();
  const [headerRef, headerDimensions] = useDimensions();

  const hasActiveTranslations = useCallback((translations: Translation[]) => {
    return translations.some((translation) =>
      ['created', 'in-progress'].includes(translation.status.value)
    );
  }, []);

  // Calculate table container height by subtracting header height and padding
  const tableContainerHeight = `calc(100vh - ${navbarHeight}px - ${headerDimensions.height}px - 4rem)`;

  const { loading, success, errorMessage } = useSelector(
    (state: RootState) => state.translations.fetchTranslations
  );

  const { translations } = useSelector(
    (state: RootState) => state.translations
  );

  // Initial fetch
  useEffect(() => {
    dispatch(fetchTranslationsRequest());
  }, [dispatch]);

  // Handle initial load state
  useEffect(() => {
    if (success !== undefined && isInitialLoad) {
      setIsInitialLoad(false);
    }
  }, [success, isInitialLoad]);

  // Polling setup
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (translations && hasActiveTranslations(translations)) {
      intervalId = setInterval(() => {
        dispatch(fetchTranslationsRequest());
      }, 10000); // Poll every 10 seconds
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [translations, dispatch, hasActiveTranslations]);

  const renderErrorMessage = () => {
    if (errorMessage) {
      return <Typography variant='body1'>{t(errorMessage)}</Typography>;
    }

    return <Typography variant='body1'>{t('An error occurred')}</Typography>;
  };

  const renderTranslations = () => {
    if (success === false) {
      return renderErrorMessage();
    }

    return (
      <TranslationsList
        translations={translations}
        containerHeight={tableContainerHeight}
        loading={isInitialLoad && loading} // Only show loading on initial load
      />
    );
  };

  return (
    <PageContainer navbarHeight={navbarHeight}>
      <PageContent>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
          }}
        >
          <div ref={headerRef as any}>
            <Typography
              sx={{
                color: primaryColor1,
                marginBottom: '1rem',
                marginTop: '1rem',
              }}
              variant='h4'
            >
              {t('Translations')}
            </Typography>
          </div>
          {renderTranslations()}
        </div>
      </PageContent>
    </PageContainer>
  );
};

export default HomePage;
