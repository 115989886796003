import styled from '@emotion/styled';
import { Button } from '@mui/material';

interface AdditionalStylesProps {
  addStyles?: string;
}

const FlexColumn = styled.div<AdditionalStylesProps>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  ${(props) => props.addStyles}
`;

const FlexRow = styled.div<AdditionalStylesProps>`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  ${(props) => props.addStyles}
`;

// Green color to represent success
const successColor = '#4CAF50';

// Cancelled is an organge that fits with the rest of my pallette
const cancelledColor = '#FFA500';

// Dark Blue (Primary/Main Color): Used for primary elements like headers and important buttons
const primaryColor1 = '#1E293B';
const primaryColor1Darkened = '#0f141d';

// Teal (Secondary Color): Used for secondary elements like secondary buttons
const primaryColor2 = '#4899A1';
const primaryColor2Darkened = '#30706f'; // Darkened version

// Neutral Gray (Background Color): Main background color for pages or sections
const backgroundColor1 = '#F4F7FA';

// Soft White (Alternate Background Color): Used for card backgrounds, modals, etc.
const backgroundColor2 = '#FFFFFF';

// Standard Text Color: Used for primary body text
const textColorPrimary = '#333333'; // Dark grey
const textColorSecondary = '#4F4F4F'; // Light grey

// Carmine: Used for error messages, invalid fields, Cancel Buttons etc.
const errorColor = '#960018';
const errorColorLightened = '#A8242A'; // Lightened version

interface ButtonProps {
  buttonColor?: string;
  hoverColor?: string;
  additionalStyles?: string;
}

const CancelButton = styled(Button)<ButtonProps>`
  background-color: ${(props) => props.buttonColor || errorColor};
  color: white;
  &:hover {
    background-color: ${(props) => props.hoverColor || errorColorLightened};
  }
  ${(props) => props.additionalStyles};
`;

const AcceptButton = styled(Button)<ButtonProps>`
  background-color: ${(props) => props.buttonColor || primaryColor2};
  color: white;
  &:hover {
    background-color: ${(props) => props.hoverColor || primaryColor2Darkened};
  }
  ${(props) => props.additionalStyles};
`;

const maxPageWidth = 1200;

const responsiveMaxPageWidth = `
  @media (max-width: 1400px) {
    max-width: 1000px;
  }

  @media (max-width: 1200px) {
    max-width: 800px;
  }

  @media (max-width: 850px) {
    max-width: 600px;
  }

  @media (max-width: 650px) {
    max-width: 500px;
  }

  @media (max-width: 550px) {
    max-width: 400px;
  }

  @media (max-width: 450px) {
    max-width: 325px;
  }

  @media (max-width: 375px) {
    max-width: 325px;
  }

  @media (max-width: 350px) {
    max-width: 275px;
  }

  @media (max-width: 315px) {
    max-width: 225px;
  }
 `;

const AuthPageContainer = styled.div`
  width: 100%;
  max-width: 800px;
  margin: 3rem auto;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 10vh;

  ${responsiveMaxPageWidth}
`;

interface PageContainerProps {
  addStyles?: string;
  navbarHeight?: number;
}

const PageContainer = styled.div<PageContainerProps>`
  box-sizing: border-box;
  width: 100%;
  height: ${(props) => `calc(100vh - ${props.navbarHeight || 0}px)`};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden; // Add this to prevent scrolling
  position: fixed; // Add this to fix the container in place
  top: ${(props) =>
    `${props.navbarHeight || 0}px`}; // Add this to position it below navbar

  // border: 10px solid blue;
`;

interface PageContentProps {
  addStyles?: string;
}

const PageContent = styled.div<PageContentProps>`
  box-sizing: border-box;
  width: 100%;
  max-width: ${maxPageWidth}px;
  padding: 2rem 1rem;
  overflow-y: auto; // Add this to make content scrollable if needed
  height: 100%; // Add this to take full height

  //  border: 5px solid pink;
`;

export {
  FlexColumn,
  FlexRow,
  primaryColor1,
  primaryColor1Darkened,
  primaryColor2,
  primaryColor2Darkened,
  backgroundColor1,
  backgroundColor2,
  textColorPrimary,
  textColorSecondary,
  errorColor,
  errorColorLightened,
  CancelButton,
  AcceptButton,
  maxPageWidth,
  responsiveMaxPageWidth,
  AuthPageContainer,
  PageContainer,
  PageContent,
  successColor,
  cancelledColor,
};
