import { Typography, Stack, Paper, Divider } from '@mui/material';
import { useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { useTranslation } from 'react-i18next';
import { grey } from '@mui/material/colors';

import {
  maxPageWidth,
  primaryColor1,
  responsiveMaxPageWidth,
} from 'src/styles/constants';
import { RootState } from 'src/store';

const StyledPageContainer = styled.div`
  width: 100%;
  max-width: ${maxPageWidth}px;
  margin: 0 auto;
  padding: 2rem;

  ${responsiveMaxPageWidth}
`;

const SectionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;

  @media (max-width: 900px) {
    grid-template-columns: 1fr;
  }
`;

const PageTitle = styled(Typography)`
  text-align: center;
  @media (max-width: 900px) {
    text-align: center;
  }
`;

const InfoLabel = styled(Typography)`
  color: ${grey[600]};
  font-size: 0.875rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const InfoValue = styled(Typography)`
  font-size: 1rem;
  font-weight: 500;
  color: ${grey[900]};
`;

const InfoGroup = styled(Stack)`
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AccountPage = () => {
  const { t } = useTranslation();
  const { user } = useSelector((state: RootState) => state.user);

  return (
    <StyledPageContainer>
      <PageTitle variant='h4' color={primaryColor1} gutterBottom sx={{ mb: 4 }}>
        {t('Account')}
      </PageTitle>

      <SectionsContainer>
        <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
          <Typography
            variant='h5'
            color={primaryColor1}
            gutterBottom
            align='left'
          >
            {t('User')}
          </Typography>
          <Divider sx={{ my: 2 }} />

          <InfoGroup spacing={1}>
            <InfoLabel>{t('Username')}</InfoLabel>
            <InfoValue>{user?.username || '-'}</InfoValue>
          </InfoGroup>

          <InfoGroup spacing={1}>
            <InfoLabel>{t('Email')}</InfoLabel>
            <InfoValue>{user?.email || '-'}</InfoValue>
          </InfoGroup>
        </Paper>

        <Paper elevation={0} sx={{ p: 3, borderRadius: 2 }}>
          <Typography
            variant='h5'
            color={primaryColor1}
            gutterBottom
            align='left'
          >
            {t('Organization')}
          </Typography>
          <Divider sx={{ my: 2 }} />

          <InfoGroup spacing={1}>
            <InfoLabel>{t('Name')}</InfoLabel>
            <InfoValue>{user?.organization?.label || '-'}</InfoValue>
          </InfoGroup>

          <InfoGroup spacing={1}>
            <InfoLabel>{t('Payment Plans')}</InfoLabel>
            <Stack spacing={2}>
              <InfoValue>
                {t('Pay as you go')}: $
                {user?.organization?.paygPricePlan.pagePrice}{' '}
                {user?.organization.paygPricePlan.currency.value}
                &nbsp;{t('per page')}
              </InfoValue>
              <InfoValue>
                {t('Monthly Plan')}: {t('None')}
              </InfoValue>
            </Stack>
          </InfoGroup>
        </Paper>
      </SectionsContainer>
    </StyledPageContainer>
  );
};

export default AccountPage;
