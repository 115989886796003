import api from '../../utils/api';
import {
  UpdateBillingAddressPayload,
  UserLoginWithGooglePayload,
} from '../models/UserModels';

export const updateBillingAddress = (payload: UpdateBillingAddressPayload) => {
  return api.put('/users/billing-address', payload, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const updateUserLanguage = (token: string, language: 'en' | 'fr') => {
  return api.patch(
    '/user/language',
    { language },
    {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  );
};

export const loginWithGoogle = (payload: UserLoginWithGooglePayload) => {
  return api.post('/auth/google', payload);
};

export const acceptPrivacyPolicy = () => {
  return api.post('/privacy/accept');
};

// Assuming a similar structure for accepting Terms of Service
export const acceptTermsOfService = () => {
  return api.post('/terms/accept');
};

//export const createUniqueVisitor = (fbclid: string | null) => {
//return api.post('/visitor', { fbclid });
//};

export const createUniqueVisitor = () => {
  return api.post('/visitor');
};

export const getAuthTokenCheck = () => {
  return api.get('/auth/check');
};

export const contactUs = (token: string, message: string, email: string) => {
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  return api.post('/contact', { message, email });
};

export const fetchUser = () => {
  return api.get('/user');
};

export const registerEnterpriseUser = (payload: {
  email: string;
  password: string;
  activationCode: string;
  organizationId: number;
}) => {
  // TODO stripe the /api/ from the path if admin is own server
  return api.post('/admin/enterprise/users/create', payload);
};

export const loginEnterpriseUser = (payload: {
  email: string;
  password: string;
}) => {
  return api.post('/enterprise/users/login', payload);
};

export const forgotPassord = (email: string) => {
  return api.post('/auth/forgot-password', { email });
};

export const resetPassword = (
  email: string,
  token: string,
  password: string
) => {
  return api.post('/auth/reset-password', { email, token, password });
};
