import {
  TargetLanguage,
  TranslationPayload,
  PaygTranslationPayment,
  Translation,
} from '../models/TranslationModels';

export enum TranslationActionTypes {
  FETCH_TARGET_LANGUAGES_REQUEST = 'FETCH_TARGET_LANGUAGES_REQUEST',
  FETCH_TARGET_LANGUAGES_SUCCESS = 'FETCH_TARGET_LANGUAGES_SUCCESS',

  CREATE_TRANSLATION_REQUEST = 'CREATE_TRANSLATION_REQUEST',
  CREATE_TRANSLATION_SUCCESS = 'CREATE_TRANSLATION_SUCCESS',
  CREATE_TRANSLATION_FAILURE = 'CREATE_TRANSLATION_FAILURE',
  RESET_CREATE_TRANSLATION_STATE = 'RESET_CREATE_TRANSLATION_STATE',

  UPDATE_TRANSLATION_REQUEST = 'UPDATE_TRANSLATION_REQUEST',
  UPDATE_TRANSLATION_SUCCESS = 'UPDATE_TRANSLATION_SUCCESS',
  UPDATE_TRANSLATION_FAILURE = 'UPDATE_TRANSLATION_FAILURE',

  CREATE_DELIVERY_EMAIL_REQUEST = 'CREATE_DELIVERY_EMAIL_REQUEST',
  CREATE_DELIVERY_EMAIL_SUCCESS = 'CREATE_DELIVERY_EMAIL_SUCCESS',

  GET_DELIVERY_EMAIL_REQUEST = 'GET_DELIVERY_EMAIL_REQUEST',
  GET_DELIVERY_EMAIL_SUCCESS = 'GET_DELIVERY_EMAIL_SUCCESS',

  FETCH_TRANSLATIONS_REQUEST = 'FETCH_TRANSLATIONS_REQUEST',
  FETCH_TRANSLATIONS_SUCCESS = 'FETCH_TRANSLATIONS_SUCCESS',
  FETCH_TRANSLATIONS_FAILURE = 'FETCH_TRANSLATIONS_FAILURE',

  CREATE_TRANSLATION_PAYMENT_REQUEST = 'CREATE_TRANSLATION_PAYMENT_REQUEST ',
  CREATE_TRANSLATION_PAYMENT_SUCCESS = 'CREATE_TRANSLATION_PAYMENT_SUCCESS ',
  CREATE_TRANSLATION_PAYMENT_FAILURE = 'CREATE_TRANSLATION_PAYMENT_FAILURE',

  UPDATE_TRANSLATION_PAYMENT_STATUS = 'UPDATE_TRANSLATION_PAYMENT_STATUS',
  UPDATE_TRANSLATION_PAYMENT_STATUS_SUCCESS = 'UPDATE_TRANSLATION_PAYMENT_STATUS_SUCCESS',
  UPDATE_TRANSLATION_PAYMENT_STATUS_FAILURE = 'UPDATE_TRANSLATION_PAYMENT_STATUS_FAILURE',

  DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_REQUEST = 'DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_REQUEST',
  DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_SUCCESS = 'DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_SUCCESS',
  DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_FAILURE = 'DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_FAILURE',

  RESET_TRANSLATION_QUOTE = 'RESET_TRANSLATION_QUOTE',

  RESET_TRANSLATION_SUCCESS = 'RESET_TRANSLATION_SUCCESS',
  RESET_TRANSLATION_LOADING = 'RESET_TRANSLATION_LOADING',

  RESET_TRANSLATION_STATE = 'RESET_TRANSLATION_STATE',
  LOGOUT = 'LOGOUT',
}

export interface TranslationAction {
  type: TranslationActionTypes;
  payload?: any;
}

export const createTranslation = ({
  formData,
  targetLanguage,
  keepDocumentFormatting,
}: TranslationPayload) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_REQUEST,
  payload: { formData, targetLanguage, keepDocumentFormatting },
});

export const resetTranslationQuote = () => ({
  type: TranslationActionTypes.RESET_TRANSLATION_QUOTE,
});

export const createTranslationRequest = (formData: FormData) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_REQUEST,
  payload: { formData },
});

export const createTranslationSuccess = (translation: Translation) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_SUCCESS,
  payload: { translation },
});

export const createTranslationQuoteFailure = (errorMessage: string) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_FAILURE,
  payload: errorMessage,
});

export const resetCreateTranslationState = () => ({
  type: TranslationActionTypes.RESET_CREATE_TRANSLATION_STATE,
});

export const createDeliveryEmailRequest = (email: string) => ({
  type: TranslationActionTypes.CREATE_DELIVERY_EMAIL_REQUEST,
  payload: email,
});

export const createOrUpdateDeliveryEmailSuccess = () => ({
  type: TranslationActionTypes.CREATE_DELIVERY_EMAIL_SUCCESS,
});

export const getDeliveryEmailRequest = () => ({
  type: TranslationActionTypes.GET_DELIVERY_EMAIL_REQUEST,
});

export const getDeliveryEmailSuccess = (email: string) => ({
  type: TranslationActionTypes.GET_DELIVERY_EMAIL_SUCCESS,
  payload: email,
});

export const updateTranslationPaymentStatus = (
  paymentId: number,
  paymentStatus: string,
  callback: () => void
) => ({
  type: TranslationActionTypes.UPDATE_TRANSLATION_PAYMENT_STATUS,
  payload: { paymentId, paymentStatus, callback },
});

export const updateTranslationPaymentStatusSuccess = () => ({
  type: TranslationActionTypes.UPDATE_TRANSLATION_PAYMENT_STATUS_SUCCESS,
});

export const updateTranslationPaymentStatusFailure = () => ({
  type: TranslationActionTypes.UPDATE_TRANSLATION_PAYMENT_STATUS_FAILURE,
});

export const createPaygTranslationPaymentRequest = (translationId: number) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_REQUEST,
  payload: { translationId },
});

export const createPaygTranslationPaymentSuccess = (
  paygTransaltionPayment: PaygTranslationPayment
) => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_SUCCESS,
  payload: paygTransaltionPayment,
});

export const createPaygTranslationPaymentFailure = () => ({
  type: TranslationActionTypes.CREATE_TRANSLATION_PAYMENT_FAILURE,
});

export const resetTranslationState = () => ({
  type: TranslationActionTypes.RESET_TRANSLATION_STATE,
});

export const fetchTargetLanguages = () => ({
  type: TranslationActionTypes.FETCH_TARGET_LANGUAGES_REQUEST,
});

export const fetchTargetLanguagesSuccess = (languages: TargetLanguage[]) => ({
  type: TranslationActionTypes.FETCH_TARGET_LANGUAGES_SUCCESS,
  payload: languages,
});

export const fetchTranslationsRequest = () => ({
  type: TranslationActionTypes.FETCH_TRANSLATIONS_REQUEST,
});

export const fetchTranslationsSuccess = (translations: Translation[]) => ({
  type: TranslationActionTypes.FETCH_TRANSLATIONS_SUCCESS,
  payload: translations,
});

export const fetchTranslationsFailure = ({
  errorMsg,
}: {
  errorMsg: string;
}) => ({
  type: TranslationActionTypes.FETCH_TRANSLATIONS_FAILURE,
  payload: { errorMsg },
});

export const deleteStripePaymentRequest = (paymentId: number) => ({
  type: TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_REQUEST,
  payload: { paymentId },
});

export const deleteStripePaymentSuccess = () => ({
  type: TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_SUCCESS,
});

export const deleteStripePaymentFailure = () => ({
  type: TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_FAILURE,
});

export const deletePaygTranslationPaymentRequest = (
  translationId: number,
  stripePaymentId: number
) => ({
  type: TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_REQUEST,
  payload: { translationId, stripePaymentId },
});

export const deletePaygTranslationPaymentSuccess = () => ({
  type: TranslationActionTypes.DELETE_PAYG_TRANSLATION_STRIPE_PAYMENT_SUCCESS,
});
